import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const RichEditor = ({value, name, recordValue}) =>
{
  const editorRef = useRef(null);

  return (
        <Editor
            apiKey='jd4557x1tu4smt3dx84fu8w46jucfholkwkniplslyz85unk'
            onInit={(evt, editor) => editorRef.current = editor}
            value={value}
            onEditorChange={(value) => {
                recordValue(value, name);
            }}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
                content_style: 'body { font-size:14px }'
            }}
        />
  );
}