import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"

import styles from "./Page.module.scss"

import { ActiveOrdersColumns } from "./../../APP/COMPONENTS/ActiveOrdersColumns"
import { MessageBox } from "./../../APP/COMPONENTS/MessageBox"
import { HeadingButton } from "./../../APP/COMPONENTS/HeadingButton"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class ActiveOrders extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false
        }

        this.data_string = 'ActiveOrders';
    }

    componentDidMount = () =>
    {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REFETCH !== prevProps.REFETCH) {
            if (this.props.REFETCH && this.props.REFETCH.length) {
                if (this.props.REFETCH.includes(this.data_string)) {
                    this.fetchData();
                }
            }
        }
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getActiveSalesByLines',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    render() {
        if (!this.props.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>Active orders</title>
                </Helmet>
                <h1>Active orders</h1>

                {!this.state.LOADED && <InnerLoader/>}

                {this.state.LOADED && this.state.DATA.lines && !this.state.DATA.lines.length && <MessageBox text="No data recieved from server!"/>}

                {this.state.LOADED && this.state.DATA.lines && this.state.DATA.lines.length > 0 &&
                    <ActiveOrdersColumns data={this.state.DATA} />
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(ActiveOrders))
