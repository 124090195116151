import React, {useState, useMemo} from 'react';
import classNames from 'classnames';
import InputMask from "react-input-mask";
import CONFIG from './../../../SYSTEM/Config.js'

import { Request } from "./../../../SYSTEM/Request"

import styles from "./../GuestHomepage.module.scss"

export const WhatsappForm = ({switchView, setPhone}) =>
{
    const [error, setError] = useState(false)
    const [phone, recordPhone] = useState('+7-___-___-__-__')
    const [sending, startSendingData] = useState(false)

    const isReadyToGetCode = useMemo(() =>
    {
        if (phone) {
            let phoneNumber = phone.toString().replace(/\D+/g, '');
            if (phoneNumber.length == 11) {
                return true;
            }
        }

        return false
    }
    ,[phone])

    const submitForm = async () =>
    {
        if (!isReadyToGetCode || sending) {
            return
        }

        startSendingData(true)

        try {
            let SEND_DATA = {
                action:     'getCode',
                phone:       phone
            };

            let data = await Request(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                startSendingData(false)
                setError(false)
                setPhone(phone)
                switchView('code')
            } else {
                setError(true)
                startSendingData(false)
            }
        } catch (error) {
            console.log(error)
            startSendingData(false)
        }
    }

    const handleKeyPress = (e) =>
    {
        if (e.key === 'Enter') {
			submitForm()
		}
    }

    return (
        <>
            <div className={styles.whatsappText}>
                Укажите ваш номер WhatsApp и мы пришлём код для входа
            </div>
            <div className={styles.inputsHolder}>
                <InputMask
                    placeholder="+7-"
                    type="tel"
                    className={classNames(styles.loginInput, {[styles.error]: error})}
                    mask="+7-999-999-99-99"
                    onChange={(e) => recordPhone(e.target.value)}
                    autoFocus={true}
                    onKeyDown={handleKeyPress}
                />
                {
                    error && <div className={styles.errorText}>phone not found</div>
                }
            </div>
            <a 
                onClick={submitForm}
                className={
                    classNames(
                        styles.loginButton, styles.marginTop, {
                            [styles.inactive]: !isReadyToGetCode
                        }
                    )
                }
            >
                Прислать код
            </a>
        </>
    )
}
