import React, {Component} from 'react';
import {Routes, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Cookies from 'universal-cookie';
import CONFIG from './../SYSTEM/Config.js'

import {Request} from "./../SYSTEM/Request"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ShortLinks from '../APP/ROUTES/ShortLinks'; 
import Customers from '../APP/ROUTES/Customers'; 
import Map from '../APP/ROUTES/Map'; 
import Managers from '../APP/ROUTES/Managers'; 
import TodayPricing from '../APP/ROUTES/TodayPricing'; 
import ActiveOrders from '../APP/ROUTES/ActiveOrders';
import InStock from '../APP/ROUTES/InStock';
import RawMaterials from '../APP/ROUTES/RawMaterials';
import OperationalEfficiency from '../APP/ROUTES/OperationalEfficiency';
import Users from './../APP/ROUTES/Users';
import HomePage from './../APP/ROUTES/HomePage';
import Error from './../APP/ROUTES/Error';

import OrdersList from './../APP/ROUTES/OrdersList';

import Landings from './../APP/ROUTES/Landings';

import Promocodes from '../APP/ROUTES/Promocodes';

import Header from './../APP/COMPONENTS/Common/Header';
import LeftNav from './../APP/COMPONENTS/Common/LeftNav';

import Modals from './../APP/MODALS/Modals';

import Landing from './../APP/ROUTES/Landing';

const cookies = new Cookies();

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        FETCH_UPDATE: state.FETCH_UPDATE,
        FETCH_PENDING_ORDERS: state.FETCH_PENDING_ORDERS,
        HIDE_NAVS: state.HIDE_NAVS
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            LOADED: false
        };

        this.myRef = React.createRef();
    }

    componentDidMount() {
        let COOKIE = cookies.get(CONFIG.COOKIE_TITLE);
        if ((COOKIE !== null) && (typeof COOKIE !== "undefined") && (COOKIE !== "undefined")) {
            this.getAuthByCookie(COOKIE);
        } else {
            this.setState({LOADED: true});
        }
    }

    getAuthByCookie = async (COOKIE) => {
        try {
            let SEND_DATA = {
                action: 'loginWithCookie',
                cookie: COOKIE
            };

            let data = await Request(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data);
            }

            let COMPONENT = this;
            setTimeout(function(){
                COMPONENT.setState({LOADED: true});
            },200);
        } catch (error) {
        }
    }

    scrollToTop = () => {
        this.myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    renderAuthParts = () =>
    {
        if (this.props.USER && !this.props.HIDE_NAVS) {
            return (
                <>
                    <Header />
                    <LeftNav />
                </>
            );
        }
    }

    getClassName = () =>
    {
        if (this.props.USER) {
            return 'auth_page';
        }

        return '';
    }

    render() {
        if (!this.state.LOADED) {
            return <div></div>
        }
        return (
            <>
                {this.renderAuthParts()}
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/users" element={<Users />} />
                    <Route exact path="/efficiency" element={<OperationalEfficiency />} />
                    <Route exact path="/materials" element={<RawMaterials />} />
                    <Route exact path="/stock" element={<InStock />} />
                    <Route exact path="/orders" element={<ActiveOrders />} />
                    <Route exact path="/pricing" element={<TodayPricing />} />
                    <Route exact path="/managers" element={<Managers />} />
                    <Route exact path="/map" element={<Map />} />
                    <Route exact path="/customers" element={<Customers />} />
                    <Route exact path="/shortlinks" element={<ShortLinks />} />
                    <Route exact path="/orderslist" element={<OrdersList />} />
                    <Route exact path="/Promocodes" element={<Promocodes />} />
                    <Route exact path="/Landings" element={<Landings />} />
                    <Route exact path="/l/:landingCode" element={<Landing />} />
                    <Route element={<Error />} />
                </Routes>
                <Modals/>
            </>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
