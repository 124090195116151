import React, {useState, useEffect} from 'react';
import classNames from 'classnames'

import { UsersTableRow } from "./UsersTableRow"

import styles from "./UsersTable.module.scss"

export const UsersTable = ({users}) =>
{
    return (
        <div className={styles.tableWrapper}>
            <table class={styles.usersTable}>
                <tbody>
                    {users.map((user, index) => {
                        return <UsersTableRow key={index} user={user} />
                    })}
                </tbody>
            </table>
        </div>
    )
}
