import React, {useState, useMemo} from 'react';
import classNames from 'classnames';
import InputMask from "react-input-mask";
import CONFIG from '../../../SYSTEM/Config.js'

import { Request } from "../../../SYSTEM/Request"

import styles from "./../GuestHomepage.module.scss"

export const LoginForm = ({switchView, doLogin}) =>
{
    const [login, recordLogin] = useState(false)
    const [password, recordPassword] = useState(false)
    const [sending, startSendingData] = useState(false)
    const [error, setError] = useState(false)

    const isReadyToLogin = useMemo(() =>
    {
        if (login && login.length > 2 && password && password.length > 2) {
            return true
        }

        return false
    }
    ,[login, password])

    const submitForm = async () =>
    {
        if (!isReadyToLogin || sending) {
            return
        }

        startSendingData(true)
        setError(false)

        try {
            let SEND_DATA = {
                action:     'login',
                login:      login,
                password:   password
            };

            let data = await Request(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                startSendingData(false)
                doLogin(data)
            } else {
                setError(true)
                startSendingData(false)
            }
        } catch (error) {
            console.log(error)
            startSendingData(false)
        }
    }

    const handleKeyPress = (e) =>
    {
        if (e.key === 'Enter') {
			submitForm()
		}
    }

    return (
        <>
            <div className={styles.inputsHolder}>
                <input
                    type="text"
                    className={classNames(styles.loginInput, {[styles.error]: error})}
                    onChange={(e) => recordLogin(e.target.value)}
                    placeholder="Логин"
                    onKeyDown={handleKeyPress}
                />
                <input
                    type="password"
                    className={classNames(styles.loginInput, {[styles.error]: error})}
                    onChange={(e) => recordPassword(e.target.value)}
                    placeholder="Пароль"
                    onKeyDown={handleKeyPress}
                />
                {
                    error && <div className={styles.errorText}>проверьте указанные данные</div>
                }
            </div>
            <a className={styles.switchButton} onClick={() => switchView('whatsapp')}>
                Зайти через код WhatsApp
            </a>
            <a 
                onClick={submitForm}
                className={
                    classNames(
                        styles.loginButton, {
                            [styles.inactive]: !isReadyToLogin
                        }
                    )
                }
            >
                Войти
            </a>
        </>
    )
}
