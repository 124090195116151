import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import classNames from 'classnames'
import { getRandomColor } from "./../../SYSTEM/Functions"

import { useCoolDown } from "./../../hooks.js"
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"

import Pencil from'./../../APP/ASSETS/pencil.svg' 

import styles from "./GroupPricing.module.scss"

export const GroupPricing = ({group}) =>
{
    const dispatch = useDispatch()
    const user = useSelector(state => state.USER)
    const [usePrice, setUsePrice] = useState(group.price ? group.price : 0)

    useEffect(() =>
    {
        if (usePrice !== group.price) {
            setUsePrice(group.price ? group.price : 0)
        }
    }, [group.price])

    const editGroupPricing = () =>
    {
        let useGroup = Object.assign({}, {...group});
        useGroup.price = usePrice;

        dispatch({
            type: 'TOGGLE_MODAL',
            data: {
                key: "EditGroupPricing",
                status: useGroup
            }
        })
    }

    const refreshProducts = () =>
    {
        dispatch({
            type: 'REFETCH',
            data: ['TodayPricing']
        })
    }
    const priceSaved = useCoolDown(refreshProducts, 1000)

    const savePrice = async (price) =>
    {
        try {
            let SEND_DATA = {
                action:     'editGroupPrice',
                token:      user.token,
                id:         group.id,
                price:      price
            };

            await Request(SEND_DATA,CONFIG.API_GATE);

            priceSaved();
            
        } catch (error) {
            console.log(error)
        }
    }
    const priceChanged = useCoolDown(savePrice, 500)

    const color = group.color.length ? group.color : getRandomColor()

    return (
        <div className={styles.groupColumn}>
            <a className={styles.editButton} onClick={() => editGroupPricing()}><img src={Pencil} /></a>
            <div className={styles.groupHeading} style={{background: color}}>
                {group.name}
                <div className={styles.groupPricing}>
                    <input type="number" value={usePrice} onChange={(input) => {setUsePrice(input.target.value); priceChanged(input.target.value)}} />
                    $
                </div>                
            </div>
            <div className={styles.productsList}>
                {group.products.map((product, index) => {
                    if (product.active == 1) {
                        return (
                            <div className={styles.productRow} key={index}>
                                <div className={styles.productTitle}>
                                    {product.name}
                                </div>
                                <div className={styles.productPrice}>
                                    {product.price} $
                                </div>
                            </div>
                        )
                    }
                })}
            </div>           
        </div>
    )
}
