import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import { withRouter } from "./../../hooks.js"
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'

import styles from "./Page.module.scss"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Error extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            USER: this.props.USER
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.USER !== prevProps.USER) {
            this.setState({USER: this.props.USER});
        }
    }

    render() {
        if (!this.state.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>404 ошибка</title>
                </Helmet>
                404 error
                <Link to="/">На главную</Link>
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Error))
