import React, { useEffect, useRef, useMemo, useState } from 'react';

import { EfficiencySpeedometer } from "./EfficiencySpeedometer"
import { sumBeautifier } from "./../../SYSTEM/Functions"

import styles from "./OperationalColumns.module.scss"

const showLine = (line) =>
{
    if (line.statistics.orders > 0) {
        return true;
    }

    return false;
}

export const OperationalColumns = ({data}) =>
{
    return (
        <div className={styles.wrapper}>
            <div className={styles.columnsHolder}>
                {data.map((line, index) => {
                    if (!showLine(line)) { return null; }
                    return (
                        <div key={index} className={styles.operationalColumn}>
                            <EfficiencySpeedometer
                                number={line.id}
                                title={line.title}
                                sum={line.statistics.sales}
                                text={line.statistics.orders + ' orders / ' + line.statistics.days + ' days'}
                                percent={line.statistics.loaded}
                                speedTitle="load"
                            />
                        </div>
                    )
                })}
            </div>
            <div className={styles.greyRow}>
                <div className={styles.columnsHolder}>
                    {data.map((line, index) => {
                        if (!showLine(line)) { return null; }
                        return (
                            <div key={index} className={styles.operationalColumn}>
                                <div className={styles.dataHolder}>
                                    {line.statistics.manufactured} tons
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles.operationalColumn}>
                        <div className={styles.dataHolderHeader}>
                            Manufactured
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greyRow}>
                <div className={styles.columnsHolder}>
                    {data.map((line, index) => {
                        if (!showLine(line)) { return null; }
                        return (
                            <div key={index} className={styles.operationalColumn}>
                                <div className={styles.dataHolder}>
                                    {line.statistics.defective} tons / {line.statistics.defective_percent}%
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles.operationalColumn}>
                        <div className={styles.dataHolderHeader}>
                            Defective
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greyRow}>
                <div className={styles.columnsHolder}>
                    {data.map((line, index) => {
                        if (!showLine(line)) { return null; }
                        return (
                            <div key={index} className={styles.operationalColumn}>
                                <div className={styles.dataHolder}>
                                    {line.statistics.margin} %
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles.operationalColumn}>
                        <div className={styles.dataHolderHeader}>
                            Margin
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greyRow}>
                <div className={styles.columnsHolder}>
                    {data.map((line, index) => {
                        if (!showLine(line)) { return null; }
                        return (
                            <div key={index} className={styles.operationalColumn}>
                                <div className={styles.dataHolder}>
                                    {sumBeautifier(line.statistics.sales)}
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles.operationalColumn}>
                        <div className={styles.dataHolderHeader}>
                            Gross sales
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greyRow}>
                <div className={styles.columnsHolder}>
                    {data.map((line, index) => {
                        if (!showLine(line)) { return null; }
                        return (
                            <div key={index} className={styles.operationalColumn}>
                                <div className={styles.dataHolder}>
                                    {sumBeautifier(line.statistics.net)}
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles.operationalColumn}>
                        <div className={styles.dataHolderHeader}>
                            Net
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.greyRow}>
                <div className={styles.columnsHolder}>
                    {data.map((line, index) => {
                        if (!showLine(line)) { return null; }
                        return (
                            <div key={index} className={styles.operationalColumn}>
                                <div className={styles.dataHolder}>
                                    {line.statistics.loaded}%
                                </div>
                            </div>
                        )
                    })}
                    <div className={styles.operationalColumn}>
                        <div className={styles.dataHolderHeader}>
                            Loaded
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}