import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"

import { Modal } from "./../../APP/COMPONENTS/Modal"
import Settings from "./Settings"
import AddUser from "./AddUser"
import EditUser from "./EditUser"

import AddCustomer from "./AddCustomer"
import EditCustomer from "./EditCustomer"

import EditGroupPricing from "./EditGroupPricing"
import EditGroupAmount from "./EditGroupAmount"

import AddShortLink from "./AddShortLink"
import EditShortLink from "./EditShortLink"

import AddPromocode from "./AddPromocode"
import EditPromocode from "./EditPromocode"

import AddLanding from "./AddLanding"
import EditLanding from "./EditLanding"

import LandingVisits from "./LandingVisits"
import LandingSubmits from "./LandingSubmits"
import ShortLinkVisits from "./ShortLinkVisits"

class Modals extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
			<>
				<Modal
                    header="Настройки"
					content={<Settings />}
					modalKey="Settings"
				/>
                <Modal
                    header="Создать пользователя"
					content={<AddUser />}
					modalKey="AddUser"
                    thin
				/>
                <Modal
                    header="Редактировать пользователя"
					content={<EditUser />}
					modalKey="EditUser"
                    thin
				/>
				<Modal
                    header="Создать щорт-линк"
					content={<AddShortLink />}
					modalKey="AddShortLink"
				/>
                <Modal
                    header="Редактировать шорт-линк"
					content={<EditShortLink />}
					modalKey="EditShortLink"
				/>
				<Modal
                    header="Создать промокод"
					content={<AddPromocode />}
					modalKey="AddPromocode"
				/>
                <Modal
                    header="Редактировать промокод"
					content={<EditPromocode />}
					modalKey="EditPromocode"
				/>
				<Modal
                    header="Создать лендинг"
					content={<AddLanding />}
					modalKey="AddLanding"
				/>
                <Modal
                    header="Редактировать лендинг"
					content={<EditLanding />}
					modalKey="EditLanding"
				/>
                <Modal
                    header="Добавить проект"
					content={<AddCustomer />}
					modalKey="AddCustomer"
				/>
                <Modal
                    header="Редактировать проект"
					content={<EditCustomer />}
					modalKey="EditCustomer"
				/>
                <Modal
                    header="Edit Pricing"
					content={<EditGroupPricing />}
					modalKey="EditGroupPricing"
				/>
                <Modal
                    header="Edit left others"
					content={<EditGroupAmount />}
					modalKey="EditGroupAmount"
				/>
				<Modal
                    header="Просмотры шорт-линка"
					content={<ShortLinkVisits />}
					modalKey="ShortLinkVisits"
				/>
				<Modal
                    header="Просмотры лендинга"
					content={<LandingVisits />}
					modalKey="LandingVisits"
				/>
				<Modal
                    header="Заполнения лендинга"
					content={<LandingSubmits />}
					modalKey="LandingSubmits"
				/>				
			</>
		)
    }
}

export default Modals