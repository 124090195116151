import React, {Component, useMemo} from 'react';
import Select from 'react-select'

import styles from "./Selectable.module.scss"

export const Selectable = ({...props}) => 
{
    let selectedValue = useMemo(() => 
    {
        if (!props.value) {
            return false;
        }
        let selected = props.options.filter((obj) => {
            if (obj.value == props.value ) {
                return true;
            }
            return false;
        });

        if (selected.length) {
            return selected[0]
        }

        return false;
    },[props.value, props.options])

    return (
        <Select
            className={styles.select}
            classNamePrefix={props.classNamePrefix ? props.classNamePrefix : 'react-select'}
            placeholder={props.placeholder}
            label={props.placeholder}
            options={props.options}
            onChange={(option) => props.recordValue(option.value, props.name)}
            value={selectedValue}
        />
    );
}