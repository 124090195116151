import React, {Component, useState, useMemo} from 'react';
import { withRouter } from "./../../hooks.js"
import classNames from 'classnames';
import InputMask from "react-input-mask";
import CONFIG from './../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import { Request } from "./../../SYSTEM/Request"

import { LoginForm } from "./Auth/LoginForm"
import { WhatsappForm } from "./Auth/WhatsappForm"
import { CodeForm } from "./Auth/CodeForm"

import styles from "./GuestHomepage.module.scss"

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class GuestHomepage extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            VIEW: 'login',
            PHONE: ''
        }
    }

    renderContentBlock = () =>
    {
        switch (this.state.VIEW)
        {
            case 'login':
                return <LoginForm switchView={this.switchView} doLogin={this.props.doAuth} />
            case 'whatsapp':
                return <WhatsappForm switchView={this.switchView} setPhone={this.setPhone} />
            case 'code':
                return <CodeForm switchView={this.switchView} phone={this.state.PHONE} doLogin={this.props.doAuth} />
        }
    }

    setPhone = (phone) =>
    {
        this.setState({PHONE: phone})
    }

    switchView = (view) =>
    {
        this.setState({VIEW: view})
    }

	render() { 
        return (
            <div className={styles.intro}>
                <div className={styles.loginBlock}>
                    <img src="/images/logo.png" className={styles.logo} />
                    <div className={styles.introText}>
                        Добро пожаловать в CRM
                    </div>
                    <div className={styles.formBlock}>
                        {this.renderContentBlock()}
                    </div>
                </div>
            </div>
    	);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(GuestHomepage))
