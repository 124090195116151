import { sumBeautifier } from "./../../../SYSTEM/Functions"

import styles from "./SalesChart.module.scss"

const percentDiff = (current, past) =>
{
    if (past == 0 && current == 0) {
        return 0;
    }

    if (past == 0) {
        return 100;
    }
    let onePercent = past / 100;

    return Math.round(current / onePercent - 100)
}

const drawTooltips = (chart, options) =>
{
    const chartContainer = document.getElementById(options.containerID);

    let sets = chart.data.datasets
    let points = {}

    for (let i = 0; i < sets.length; i++) {
        let meta = chart.getDatasetMeta(i);

        for (let j = 0; j < meta.data.length; j++) {
            if (typeof points[j] == "undefined") {
                points[j] = {
                    x : Math.round(meta.data[j].x), 
                    y: Math.round(meta.data[j].y),
                    index: meta.data[j].$context.dataIndex
                }
            } else {
                if (points[j].y > Math.round(meta.data[j].y)) {
                    points[j].y = Math.round(meta.data[j].y)
                }
            }
        }
    }

    for (let i in points) {
        let point = points[i];

        let tooltip = document.createElement('div');
        tooltip.className = styles.tooltip;
        tooltip.style.left = point.x + 'px';
        tooltip.style.top = point.y - 10 + 'px';

        let sum = 0;

        for (let j = 0; j < chart.config.data.datasets.length; j++) {
            let item = chart.config.data.datasets[j]
            let currentStat = chart.config.data.datasets[j].data[i]
            let pastStat = chart.config.data.datasets[j].pastData[i]

            sum += currentStat;
            
            // let tooltipLine = document.createElement('div');
            // tooltipLine.className = styles.tooltipData;
            // tooltipLine.style.borderColor = item.backgroundColor;
            // tooltipLine.innerHTML = percentDiff(currentStat, pastStat) + '%'

            // let tooltipHover = document.createElement('div');
            // tooltipHover.className = styles.tooltipHover;
            // tooltipHover.style.borderColor = item.backgroundColor;
            // tooltipHover.innerHTML = '<strong>' + item.label + '</strong>';
            // tooltipHover.innerHTML += '<br/>';
            // tooltipHover.innerHTML += 'Current year: ' + sumBeautifier(currentStat);
            // tooltipHover.innerHTML += '<br/>';
            // tooltipHover.innerHTML += 'Past year: ' + sumBeautifier(pastStat);

            // tooltipLine.appendChild(tooltipHover)

            // tooltip.appendChild(tooltipLine);
        }

        let sumLine = document.createElement('div');
        sumLine.className = styles.tooltipSum;
        sumLine.innerHTML = sumBeautifier(sum)
        tooltip.insertBefore(sumLine, tooltip.firstChild);

        if (chartContainer) {
            chartContainer.appendChild(tooltip);
        }
    }
}

export const tooltipPlugin = {
    id: 'tooltip',
    afterRender: function (chart, args, options) {
        drawTooltips(chart, options);
    },
    beforeDatasetDraw: function () {
        let drawnTooltips = document.getElementsByClassName(styles.tooltip);
        if (drawnTooltips.length > 0) {
            for (let i = 0; i < drawnTooltips.length; i++) {
                drawnTooltips[i].remove()
            }
        }
    }
}