import React, {useState, useMemo} from 'react';

export const Avatar = ({src, size, radius}) =>
{
    return (
        <div style={{
            backgroundImage: src ? 'url(' + src + ')' : 'url(/images/no-avatar.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: size ? size : '38px',
            height: size ? size : '38px',
            borderRadius: radius ? radius : '50%'
        }} />
    )
}
