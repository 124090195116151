import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import classNames from "classnames"

import { Selectable } from "../COMPONENTS/Form/Selectable"
import TextInput from "../COMPONENTS/Form/TextInput"
import PhoneInput from "../COMPONENTS/Form/PhoneInput"
import { Avatar } from "../COMPONENTS/Common/Avatar"

import Plus from'./../../APP/ASSETS/plus.svg' 
import Lock from'./../../APP/ASSETS/lock.svg' 

import styles from "./Form.module.scss"

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        EDIT: state.MODALS_LIST.EditUser
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class EditUser extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            id: this.props.EDIT.id,
            name: this.props.EDIT.name,
            login: this.props.EDIT.login,
            last_name: this.props.EDIT.last_name,
            email: this.props.EDIT.email,
            role: this.props.EDIT.role,
            password: '',
            phone: this.props.EDIT.phone,
            avatar: false           
        }
    }

    isReadyToSave = () =>
    {
        if (this.state.name.length < 2 || this.state.login.length < 2) {
            return false;
        }

        let phoneNumber = this.state.phone.toString().replace(/\D+/g, '');
        if (phoneNumber.length !== 11) {
            return false;
        }

        if (this.state.password.length > 0 && this.state.password.length < 5) {
            return false;
        }

        return true;
    }

    recordValue = (string, key) =>
    {
        this.setState({[key]: string})
    }

    renderAvatarLoader = () =>
    {
        let AvatarBlock = (
            <div className={styles.avatarPlaceholder}>
                Фото
            </div>
        )
        
        if (this.props.EDIT.image || this.state.avatar) {
            let preview = false;
            if (this.state.avatar) {
                preview = URL.createObjectURL(this.state.avatar);
            }

            AvatarBlock = (
                <Avatar
                    src={preview ? preview : this.props.EDIT.image}
                    size="77px"
                />
            )
        }

        return (
            <label className={styles.avatarChange}>
                {AvatarBlock}
                <img src={Plus} className={styles.plusButton} />
                <input style={{'display':'none'}} type="file" onChange={(e) => this.recordValue(e.target.files[0], 'avatar')} accept="image/*" />
            </label>
        )
    }

    removeUser = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'removeUser',
                    token:      this.props.USER.token,
                    id:         this.state.id
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.props.refetch(['Users']);
                    this.props.toggleModal({key: 'EditUser', status: false})
                }

                this.fetching = false;
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    save = async () =>
    {
        if (!this.isReadyToSave()) {
            return;
        }

        if (!this.saving) {
            this.saving = true;

            let SEND_DATA = new FormData();

            SEND_DATA.append('action', 'editUser');
            SEND_DATA.append('token', this.props.USER.token);
            SEND_DATA.append('id', this.state.id);
            SEND_DATA.append('avatar', this.state.avatar);
            SEND_DATA.append('role', this.state.role);
            SEND_DATA.append('name', this.state.name);
            SEND_DATA.append('last_name', this.state.last_name);
            SEND_DATA.append('phone', this.state.phone);
            SEND_DATA.append('email', this.state.email);
            SEND_DATA.append('login', this.state.login);
            SEND_DATA.append('password', this.state.password);

            fetch( 
                CONFIG.API_GATE, 
                {
                    credentials: 'include',
                    method: "POST",
                    body: SEND_DATA
                }
            )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status.result == 'SUCCESS') {
                    this.setState({ERROR: false})
                    this.props.refetch(['Users']);
                    this.props.toggleModal({key: 'EditUser', status: false})
                } else {
                    this.setState({ERROR: true})
                }

                this.saving = false;
            })
            .catch(err => {
                console.log(err)
                this.saving = false;
                this.setState({ERROR: true})
            })
        }
    }

    renderRemoveOption = () =>
    {
        if (this.props.USER.role == 9) {
            return <a className={styles.removeButton} onClick={() => this.removeUser()}>
                Удалить
            </a>
        }
    }

    render()
    {

        return (
			<>
                <TextInput
                    name="name"
                    value={this.state.name}
                    placeholder="Имя"
                    type="text"
                    recordValue={this.recordValue}
                />
                <TextInput
                    name="last_name"
                    value={this.state.last_name}
                    placeholder="Фамилия"
                    type="text"
                    recordValue={this.recordValue}
                />
                <PhoneInput
                    name="phone"
                    value={this.state.phone}
                    placeholder="Телефон"
                    recordValue={this.recordValue}
                />
                <TextInput
                    name="email"
                    value={this.state.email}
                    placeholder="Email"
                    type="email"
                    recordValue={this.recordValue}
                />

                {this.renderAvatarLoader()}

                <div style={{height: '50px'}} />

                <div className={styles.header}>
                    <img src={Lock} /> Настройки доступа
                </div>

                <Selectable
                    name="role"
                    value={this.state.role}
                    placeholder="Статус"
                    recordValue={this.recordValue}
                    options={[
                        { value: 2, label: 'Офис' },
                        { value: 3, label: 'Менеджер по продажам' },
                        { value: 9, label: 'Администратор' },
                    ]}
                />

                <TextInput
                    name="login"
                    value={this.state.login}
                    placeholder="Логин"
                    type="text"
                    recordValue={this.recordValue}
                    autoComplete="off"
                />

                <TextInput
                    name="password"
                    value=""
                    placeholder="Пароль"
                    type="password"
                    recordValue={this.recordValue}
                    autoComplete="off"
                />

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>Error occured while saving data!</div>}
                    <a onClick={() => this.save()} className={classNames(styles.submitButton, {[styles.inactive]: !this.isReadyToSave()})}>Сохранить изменения</a>
                </div>
                {this.renderRemoveOption()}
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(EditUser);