import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"
import { withRouter } from "../../hooks.js"

import styles from "./Page.module.scss"

import { MessageBox } from "../COMPONENTS/MessageBox"
import { OrdersListTable } from "../COMPONENTS/OrdersListTable/OrdersListTable"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class OrdersList extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            LIMIT: 20,
            START: 0,
            DATA: [],
            SHOW_BUTTON: false
        }

        this.data_string = 'OrdersList';
    }

    componentDidMount = () =>
    {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REFETCH !== prevProps.REFETCH) {
            if (this.props.REFETCH && this.props.REFETCH.length) {
                if (this.props.REFETCH.includes(this.data_string)) {
                    this.fetchData();
                }
            }
        }
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getOrdersList',
                    token:      this.props.USER.token,
                    start:      this.state.START,
                    limit:      this.state.LIMIT
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    let NEW_DATA = this.state.DATA.concat(data.orders);
                    this.setState({
                        LOADED: true, 
                        DATA: NEW_DATA, 
                        SHOW_BUTTON: NEW_DATA.length < data.total,
                        START: this.state.START + this.state.LIMIT
                    })
                } else {
                    this.fetching = false;
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    render() {
        if (!this.props.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>Orders list</title>
                </Helmet>
                <h1>Orders list</h1>

                {!this.state.LOADED && <InnerLoader/>}

                {this.state.LOADED && !this.state.DATA.length && <MessageBox text="No data recieved from server!"/>}

                {this.state.LOADED && this.state.DATA.length && 
                    <OrdersListTable
                        orders={this.state.DATA}
                        showButton={this.state.SHOW_BUTTON}
                        buttonClick={this.fetchData}
                    />
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(OrdersList))
