import React, { useEffect, useRef, useMemo, useState } from 'react';
import Chart from 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";

import { sumBeautifier, getRandomColor } from "../../../SYSTEM/Functions"

import styles from "./SalesDoughnut.module.scss"

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    if (typeof legendContainer == "undefined" || legendContainer == null) {
        return false;
    }

    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.className = styles.chartLegend;

        legendContainer.appendChild(listContainer);
    }

    return listContainer;
}

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        if (!ul) {
            return false;
        }

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');
            li.className = styles.chartLegendLi;

            li.onclick = () => {
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.className = styles.colorBox;

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
}

const getPercent = (sum, value) =>
{
    let percent = 0;
    if (value > 0) {
        percent = Math.round(value / (sum / 100))
    }
    return percent;
}

export const SalesDoughnut = ({data, showLegend = true, height = false}) =>
{
    const legendId = 'legendContainer' + Math.random(111,999);


    const chartConfig = useMemo(() => {
        let LABELS = [];
        let KEYED_DATASETS = {};
        let sum = 0;
        let COLORS = []

        for (let i = 0; i < data.length; i++) {
            let MONTH = data[i];

            for (let j = 0; j < MONTH.groups.length; j++) {
                let GROUP = MONTH.groups[j]
                let groupId = GROUP.group.id;
                let groupName = GROUP.group.name;
                let groupColor = GROUP.group.color;

                COLORS.push(groupColor.length ? groupColor : getRandomColor())

                if(i == 0) {
                    LABELS.push(groupName);
                }

                if (typeof KEYED_DATASETS[groupId] == "undefined") {
                    KEYED_DATASETS[groupId] = 0
                }

                KEYED_DATASETS[groupId] += Math.round(GROUP.currentSaleStatistics.sum)
                sum += Math.round(GROUP.currentSaleStatistics.sum)
            }
        }

        let DATA = [];
        for (let key in KEYED_DATASETS) {
            DATA.push(KEYED_DATASETS[key]);
        }

        let DISPLAY_LABELS = [];
        for (let i = 0; i < LABELS.length; i++) {
            let percent = getPercent(sum, DATA[i]);
            DISPLAY_LABELS.push(LABELS[i] + ' ' + percent + '%')
        }

        return {
            labels: DISPLAY_LABELS,
            sum: sum,
            datasets: [{
                label: '',
                backgroundColor: COLORS,
                data: DATA,
            }]
        }
    }, [data])

    const options = {
        plugins: {
            title: {
                display: false,
            },
            htmlLegend: {
                containerID: legendId,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed !== null) {
                            label += sumBeautifier(context.parsed);
                        }

                        return label;
                    }
                }
              }
        },
        responsive: true
    }

    const show = useMemo(() =>
    {
        let sum = 0;
        for (let i = 0; i < chartConfig.datasets[0].data.length; i++) {
            sum += chartConfig.datasets[0].data[i]
        }
        
        if (sum > 0) {
            return true
        }

        return false;
    },[chartConfig])

    if (!show) {
        return null
    }

    return (
        <>
            <div style={{height: height ? height : 'auto'}}>
                <Doughnut data={chartConfig} options={options} plugins={[htmlLegendPlugin]}/>
            </div>
            {showLegend && <div id={legendId} />}
        </>
    );
}
