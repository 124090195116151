import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"
import classNames from "classnames"

import styles from "./Page.module.scss"

import { FilterBar } from "./../../APP/COMPONENTS/FilterBar"
import { CustomersTable } from "./../../APP/COMPONENTS/CustomersTable"
import { MessageBox } from "./../../APP/COMPONENTS/MessageBox"
import { HeadingButton } from "./../../APP/COMPONENTS/HeadingButton"
import { ManagerFilter } from "./../../APP/COMPONENTS/ManagerFilter"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Customers extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            LOADED_MANAGERS: false,
            LOADED_GROUPS: false,
            GROUPS: [],
            FILTER: {
                active: false,
                manager: this.parseManagerQuery(),
                group: false
            }
        }

        this.data_string = 'Customers';
    }

    componentDidMount = () =>
    {
        this.getGroups();
        this.getManagers();
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REFETCH !== prevProps.REFETCH) {
            if (this.props.REFETCH && this.props.REFETCH.length) {
                if (this.props.REFETCH.includes(this.data_string)) {
                    this.fetchData();
                }
            }
        }
    }

    getGroups = async () =>
    {
        if (!this.fetchingGroups) {
            this.fetchingGroups = true;

            try {
                let SEND_DATA = {
                    action:     'getGroups',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetchingGroups = false;
                    this.setState({LOADED_GROUPS: true, GROUPS: data})
                } else {
                    this.fetchingGroups = false;
                    this.setState({LOADED_GROUPS: true, GROUPS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetchingGroups = false;
            }
        }
    }

    getManagers = async () =>
    {
        if (!this.fetchingManagers) {
            this.fetchingManagers = true;

            try {
                let SEND_DATA = {
                    action:     'getManagers',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetchingManagers = false;
                    this.setState({LOADED_MANAGERS: true, MANAGERS: data})
                } else {
                    this.fetchingManagers = false;
                    this.setState({LOADED_MANAGERS: true, MANAGERS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetchingManagers = false;
            }
        }
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getCustomers',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    openAddModal = () =>
    {
        this.props.toggleModal({key: 'AddCustomer', status: true})
    }

    selectManager = (val) =>
    {
        let FILTER = Object.assign({},{...this.state.FILTER})
        if (FILTER.manager == val) {
            FILTER.manager = false;
        } else {
            FILTER.manager = val;
        }
        this.setState({FILTER: FILTER})
    }

    selectActive = (val) =>
    {
        let FILTER = Object.assign({},{...this.state.FILTER})
        if (FILTER.active == val) {
            FILTER.active = false;
        } else {
            FILTER.active = val;
        }
        this.setState({FILTER: FILTER})
    }

    parseManagerQuery = () =>
    {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.manager !== null && params.manager > 0) {
            return parseInt(params.manager);
        }
        
        return false;
    }

    selectGroup = (val) =>
    {
        let FILTER = Object.assign({},{...this.state.FILTER})
        if (FILTER.group == val) {
            FILTER.group = false;
        } else {
            FILTER.group = val;
        }
        this.setState({FILTER: FILTER})
    }

    getGroupsOptions = () =>
    {
        let options = [{key: 'all', title: 'All'}];

        for (let i = 0; i < this.state.GROUPS.length; i++) {
            let group = this.state.GROUPS[i]

            options.push({key: group.id, title: group.name, color: group.color})
        }
        
        return options;
    }

    render() {
        if (!this.props.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>Проекты</title>
                </Helmet>
                <h1>Проекты<HeadingButton action={this.openAddModal}/></h1>

                {!this.state.LOADED && <InnerLoader/>}

                {this.state.LOADED && !this.state.DATA.length && <MessageBox text="No data recieved from server!"/>}

                {this.state.LOADED && this.state.LOADED_MANAGERS && this.state.LOADED_GROUPS && this.state.DATA.length > 0 &&
                    <>
                        <ManagerFilter
                            managers={this.state.MANAGERS}
                            selectedManager={this.state.FILTER.manager}
                            setManager={this.selectManager}
                        />
                        <CustomersTable
                            customers={this.state.DATA}
                            filter={this.state.FILTER}
                            groups={this.state.GROUPS}
                        />
                    </>
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Customers))
