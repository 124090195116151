import React, {Component, useMemo} from 'react';
import Select from 'react-select'

import styles from "./Checkbox.module.scss"

export const Checkbox = ({...props}) => 
{
    const checkBoxId = 'checkBoxId' + Math.random(111,999);

    return (
        <span className={styles.checkbox}>
            <input type="checkbox" id={checkBoxId} checked={props.value} onChange={(e) => props.recordValue(!props.value, props.name)} />
            <label htmlFor={checkBoxId}>{props.placeholder && props.placeholder.length > 0 && <span>{props.placeholder}</span>}</label>
        </span>
    )
}