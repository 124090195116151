import { useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate();
        const location = useLocation();
        
        return (
            <Component
                history={history}
                location={location}
                {...props}
            />
        )
    }
    
    return Wrapper;
}

export function useCoolDown(callback = (...args) => {}, timeout=600, async=false) {

    const timer = useRef(null)

    const [pending, setPending] = useState(false)

    const modifiedCallback = (...args) => {
        if (timer.current)
            clearTimeout(timer.current)

        timer.current = setTimeout(async () => {
            setPending(true)
            const result =  await callback(...args)
            setPending(false)
            return result
        }, timeout)
    }

    return async ? [modifiedCallback, pending] : modifiedCallback
}