import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import classNames from "classnames"

import { Checkbox } from '../COMPONENTS/Form/Checkbox';
import TextInput from "../COMPONENTS/Form/TextInput"

import styles from "./Form.module.scss"

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state) {
    return {
        USER: state.USER,
        EDIT: state.MODALS_LIST.EditGroupAmount
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class EditGroupAmount extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            products: this.getProducts()
        }
    }

    getProducts = () =>
    {
        let products = {};
        for (let i = 0; i < this.props.EDIT.products.length; i++) {
            let product = this.props.EDIT.products[i]
            products[product.id] = {
                id: product.id,
                name: product.name,
                maximum_amount: product.maximum_amount,
                minimum_amount: product.minimum_amount,
                active: product.active
            }
        }
        return products;
    }

    recordMaximum = (maximum_amount, key) =>
    {
        let products = Object.assign({},{...this.state.products});
        products[key].maximum_amount = maximum_amount;
        this.setState({products: products})
    }

    recordMinimum = (minimum_amount, key) =>
    {
        let products = Object.assign({},{...this.state.products});
        products[key].minimum_amount = minimum_amount;
        this.setState({products: products})
    }

    switchActivity = (checked, key) =>
    {
        let products = Object.assign({},{...this.state.products});
        products[key].active = !products[key].active;
        this.setState({products: products})
    }

    renderRows = () =>
    {
        let rows = [];

        for (let i in this.state.products) {
            let product = this.state.products[i]

            rows.push(
                <div className={styles.flexInlineRow} key={i}>
                    <div className={styles.inlineUnderlinedRow}>
                        <Checkbox
                            name={product.id}
                            value={product.active}
                            recordValue={this.switchActivity}
                        />
                        <div className={styles.inlineTitle}>
                            {product.name}
                        </div>
                    </div>
                    <div className={styles.inlineInput}>
                        <TextInput
                            name={product.id}
                            value={product.minimum_amount}
                            placeholder="minimum_amount"
                            type="number"
                            recordValue={this.recordMinimum}
                            inlineInput={true}
                        />
                    </div>
                    <div className={styles.inlineInput}>
                        <TextInput
                            name={product.id}
                            value={product.maximum_amount}
                            placeholder="maximum_amount"
                            type="number"
                            recordValue={this.recordMaximum}
                            inlineInput={true}
                        />
                    </div>
                </div>
            )
        }

        return rows;
    }

    save = async () =>
    {
        if (!this.saving) {
            this.saving = true;

            try {
                let SEND_DATA = {
                    action:     'editProductsAmount',
                    token:      this.props.USER.token,
                    products:   this.state.products
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.saving = false;
                    this.setState({ERROR: false})
                    this.props.refetch(['LeftOthers','RawMaterials']);
                    this.props.toggleModal({key: 'EditGroupAmount', status: false})
                } else {
                    this.saving = false;
                    this.setState({LOADED: true})
                }
            } catch (error) {
                console.log(error)
                this.saving = false;
            }
        }
    }

    render()
    {
        return (
			<>
                <div className={styles.flexRow}>
                    <div className={styles.header}>
                        {this.props.EDIT.name}
                    </div>
                    <div className={styles.flexLabelsHolder}>
                        <div className={styles.inlineInput}>
                            <div className={styles.inlineLabel}>
                                Minimum
                            </div>
                        </div>
                        <div className={styles.inlineInput}>
                            <div className={styles.inlineLabel}>
                                Maximum
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderRows()}

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>Error occured while saving data!</div>}
                    <a onClick={() => this.save()} className={styles.submitButton}>Save changes</a>
                </div>
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(EditGroupAmount);