import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import classNames from 'classnames'

import { Avatar } from "./Common/Avatar"

import styles from "./ManagerSelectOption.module.scss"

export const ManagerSelectOption = ({manager}) =>
{
    return (
        <div className={styles.optionRow}>
            <Avatar src={manager.image} size="30px" />
            <span>
                {manager.name} {manager.last_name}
            </span>
        </div>
    )
}
