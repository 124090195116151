import React, {Component} from 'react';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import classNames from "classnames"

import InnerLoader from "../COMPONENTS/Common/InnerLoader"
import { VisitLine } from "../COMPONENTS/Common/VisitLine"
import { Selectable } from "../COMPONENTS/Form/Selectable"
import { ImageBlock } from "../COMPONENTS/Common/ImageBlock"
import TextInput from "../COMPONENTS/Form/TextInput"
import { RichEditor } from "../COMPONENTS/Form/RichEditor"

import styles from "./Form.module.scss"

import Plus from'./../../APP/ASSETS/plus.svg' 

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        EDIT: state.MODALS_LIST.ShortLinkVisits
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class ShortLinkVisits extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            DATA: []
        }
    }

    componentDidMount = () =>
    {
        this.getData();
    }

    getData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getShortLinkVisits',
                    token:      this.props.USER.token,
                    id:         this.props.EDIT.id
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    render()
    {
        if (!this.state.LOADED) {
            return <InnerLoader />
        }

        return (
			<>
                {this.state.DATA.map((data, index) => {
                    return (
                        <VisitLine
                            key={index}
                            data={data}
                        />
                    )
                })}
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ShortLinkVisits);