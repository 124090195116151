export const openLogin = (data) => {
    return {
        type: 'OPEN_LOGIN',
        data: data
    };
}

export const toggleModal = (data) => {
    return {
        type: 'TOGGLE_MODAL',
        data: data
    };
}

export const doAuth = (data) => {
    return {
        type: 'DO_AUTH',
        data: data
    };
}

export const logOut = () => {
    return {
        type: 'DO_LOG_OUT'
    };
}

export const hideNavs = (data) => {
    return {
        type: 'HIDE_NAVS',
        data: data
    };
}

export const refetch = (data) => {
    return {
        type: 'REFETCH',
        data: data
    };
}