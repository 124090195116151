import React, {useMemo} from 'react';
import { Avatar } from "./Common/Avatar"
import { sumBeautifier } from "./../../SYSTEM/Functions"
import { SalesChart } from "../COMPONENTS/Charts/SalesChart"
import { SalesDoughnut } from "./Charts/SalesDoughnut"
import { StatusLine } from "./Common/StatusLine"

import styles from "./ManagerStatistics.module.scss"

const ManagerInfo = ({data}) =>
{
    return (
        <div className={styles.managerInfo}>
            <Avatar src={data.manager.image} />
            <span>{data.manager.name} {data.manager.last_name}</span>
        </div>
    )
}

export const ManagerStatistics = ({data}) =>
{
    const yearCounts = useMemo(() =>
    {
        let years = {};

        for (let i = 0; i < data.sales.length; i++) {
            let month = data.sales[i];
            let year = month.date.split('-')[0];

            if (typeof years[year] == "undefined") {
                years[year] = 0;
            }

            for (let j = 0; j < month.groups.length; j++) {
                let group = month.groups[j];

                years[year] += group.currentSaleStatistics.sum;

            }
        }

        if (Object.keys(years).length > 2) {
            let keyToDelete = Object.keys(years)[0];
            delete years[keyToDelete]
        }

        let yearCounts = []
        let maximum = 0;
        for (let year in years) {
            yearCounts.push({
                title: year,
                sum: years[year],
                maximum: false
            })

            if (maximum < years[year]) {
                maximum = years[year];
            }
        }

        for (let i = 0; i < yearCounts.length; i++) {
            if (yearCounts[i].sum == maximum) {
                yearCounts[i].maximum = true;
            }
        }

        return yearCounts;
    }, [data])

    let chartData = data.sales.splice(11);

    return (
        <div className={styles.statisticsHolder}>
            <div className="row">
                <div className="twenty">
                    <ManagerInfo data={data} />
                    <div className={styles.yearColumns}>
                        {yearCounts.map((year, index) => {
                            return (
                                <div className={styles.yearColumn} key={index}>
                                    <div className={year.maximum ? styles.bigYearColumn : styles.smallYearColumn}>
                                            {sumBeautifier(year.sum)}
                                    </div>
                                    <span>{year.title}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.activeCustomers}>
                        <StatusLine value={data.activeCustomers} total={data.customersCount} postFix="" />
                        <a target="_blank" href={'/customers?manager=' + data.manager.id}>Active customers last 60 days</a>
                    </div>
                </div>
                <div className="thirty">
                    <div className={styles.donughtHolder}>
                        <SalesDoughnut data={chartData} height="90px" />
                    </div>
                </div>
                <div className="half">
                    <SalesChart data={chartData} showLegend={false} height="200px" />
                </div>
            </div>
        </div>
    )
}
