import React, {useState, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import { ListTableRow } from "./ListTableRow"

import styles from "./ListTable.module.scss"

export const Th = ({heading}) =>
{
    return (
        <th>
            { 
                heading.empty ?
                heading.title
                : 
                <div className={styles.th}>
                    {heading.title}
                </div>
            }
        </th>
    )
}

export const ListTable = ({list, headings, showButton, buttonClick, renderCell}) =>
{
    return (
        <div className={styles.tableWrapper}>
            <table className={styles.salesTable}>
                <thead>
                    <tr>
                        {
                            headings.map((heading, index) => {
                                return <Th key={index} heading={heading}  />
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, index) => {
                        return <ListTableRow key={index} item={item} headings={headings} renderCell={renderCell} />
                    })}
                </tbody>
            </table>
            {showButton && <a className={styles.loadMoreButton} onClick={() => buttonClick()}>Load more</a>}
        </div>
    )
}