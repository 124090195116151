import React, { useEffect, useRef, useMemo, useState } from 'react';
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";

import { getRandomColor } from "../../../SYSTEM/Functions"

import { htmlLegendPlugin } from "./htmlLegendPlugin"
import { tooltipPlugin } from "./tooltipPlugin"

export const SalesChart = ({data, showLegend = true, height = false, useTooltips = false}) =>
{
    const legendId = 'legendContainer' + Math.random(111,999)
    const chartId = 'chartContainer' + Math.random(111,999)

    const chartConfig = useMemo(() => {
        let LABELS = [];
        let KEYED_DATASETS = {};

        for (let i = 0; i < data.length; i++) {
            let MONTH = data[i];
            LABELS.push(MONTH.title);

            for (let j = 0; j < MONTH.groups.length; j++) {
                let GROUP = MONTH.groups[j]
                let groupId = GROUP.group.id;
                let groupName = GROUP.group.name;
                let groupColor = GROUP.group.color;

                if (typeof KEYED_DATASETS[groupId] == "undefined") {
                    KEYED_DATASETS[groupId] = {
                        label: groupName,
                        backgroundColor: groupColor.length ? groupColor : getRandomColor(),
                        borderRadius: 4,
                        borderWidth: 2,
                        borderColor: 'transparent',
                        groupIndex: j,
                        data: [],
                        pastData: []
                    }
                }

                KEYED_DATASETS[groupId].data.push(Math.round(GROUP.currentSaleStatistics.sum))
                KEYED_DATASETS[groupId].pastData.push(Math.round(GROUP.pastSaleStatistics.sum))
            }
        }

        let DATASETS = [];
        for (let key in KEYED_DATASETS) {
            DATASETS.push(KEYED_DATASETS[key]);
        }

        return {
            labels: LABELS,
            datasets: DATASETS
        }
    }, [data])

    const options = {
        plugins: {
            title: {
                display: false,
            },
            htmlLegend: {
                containerID: legendId,
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                containerID: chartId,
            }
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#818AB1',
                    font: {
                        size: 14,
                        weight: 600,
                        family: "Raleway-Regular"
                    }
                }
            },
            y: {
                stacked: true,
                display: false,
            },
        }
    }

    let plugins = [htmlLegendPlugin]
    if (useTooltips) {
        plugins.push(tooltipPlugin)
    }

    return (
        <>
            <div style={{height: height ? height : 'auto', position: 'relative'}} id={chartId}>
                <Bar data={chartConfig} options={options} plugins={plugins}/>
            </div>
            {showLegend && <div id={legendId} />}
        </>
    );
}
