import classNames from 'classnames'

import TextInput from "../../COMPONENTS/Form/TextInput"
import PhoneInput from "../../COMPONENTS/Form/PhoneInput"

import styles from "./LandingForm.module.scss"

const getFieldsList = () =>
{
    let OPTIONS = []
    
    OPTIONS.push({
        value: 'name',
        label: 'Имя',
        type: 'text'
    })
    OPTIONS.push({
        value: 'last_name',
        label: 'Фамилия',
        type: 'text'
    })
    OPTIONS.push({
        value: 'middle_name',
        label: 'Отчество',
        type: 'text'
    })
    OPTIONS.push({
        value: 'comment',
        label: 'Комментарий',
        type: 'textarea'
    })
    OPTIONS.push({
        value: 'phone',
        label: 'Телефон',
        type: 'phone'
    })
    OPTIONS.push({
        value: 'email',
        label: 'Email',
        type: 'email'
    })
    OPTIONS.push({
        value: 'adress',
        label: 'Адрес',
        type: 'textarea'
    })
    OPTIONS.push({
        value: 'company',
        label: 'Название компании',
        type: 'text'
    })
    OPTIONS.push({
        value: 'web',
        label: 'Сайт',
        type: 'text'
    })
    OPTIONS.push({
        value: 'inn',
        label: 'ИНН',
        type: 'text'
    })

    return OPTIONS;
}

const FieldRenderer = ({field, recordValue}) =>
{
    let availableFields = getFieldsList();
    for (let i = 0; i < availableFields.length; i++) {
        let fieldData = availableFields[i]

        if (field == fieldData.value) {
            if (fieldData.type == 'phone') {
                return (
                    <PhoneInput
                        name={fieldData.value}
                        placeholder={fieldData.label}
                        type="tel"
                        recordValue={recordValue}
                    />
                );
            } else {
                return (
                    <TextInput
                        name={fieldData.value}
                        placeholder={fieldData.label}
                        type={fieldData.type}
                        recordValue={recordValue}
                    />
                );
            }
        }
    }

    return null;
}

export const LandingForm = ({fields, recordValue, button, submit, submitted}) =>
{
    return (
        <>
            {fields.map((field, index) => {
                return <FieldRenderer key={index} field={field} recordValue={recordValue} />
            })}
            <div className={styles.footer}>
                {submitted ? <div className={styles.submitted}>Данные успешно отправлены</div> : <a onClick={() => submit()} className={classNames(styles.submitButton)}>{button}</a>}
            </div>
        </>
    );
}