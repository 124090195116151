export const sumBeautifier = (stringOrNumber) =>
{
    if (!stringOrNumber || stringOrNumber == 0 || stringOrNumber == '') {
        return stringOrNumber
    }
    
    let fulls = Math.round(stringOrNumber);

    if (stringOrNumber > 1000000) {
        fulls = (stringOrNumber / 1000000).toFixed(2)
        return '$ ' + fulls + 'mln';
    }

    if (stringOrNumber > 1000) {
        fulls = (stringOrNumber / 1000).toFixed(2)
        return '$ ' + fulls + 'k';
    }

    return '$ ' + fulls;
}

export const getRandomColor = () =>
{
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
}