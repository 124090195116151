import React from 'react';
import classNames from 'classnames'

import TriangleUp from'./../../../APP/ASSETS/triangle_up.svg'
import TriangleDown from'./../../../APP/ASSETS/triangle_down.svg'

import styles from "./GroupLeftProducts.module.scss"

export const GroupLeftProductFirstRow = ({sort, setSort}) =>
{
    return (
        <div className={classNames(styles.productRow, {[styles.noMarginBottom]: true})}>
            <div className={styles.productTitle} />
            <div className={styles.productAmount} />
            <div className={styles.statusLineHolder} />
            <div className={classNames(styles.plusOrder, {[styles.noBackground]: true})}>
                Plus order:
            </div>
            <div className={classNames(styles.toMake, {[styles.noBackground]: true})}>
                To make:
                <span>
                    <a className={classNames({[styles.active]: sort == 'desc'})} onClick={() => setSort('desc')}>
                        <img src={TriangleDown} />
                    </a>
                    <a className={classNames({[styles.active]: sort == 'asc'})} onClick={() => setSort('asc')}>
                        <img src={TriangleUp} />
                    </a>
                </span>
            </div>
        </div>
    )
}