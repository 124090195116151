import React, {useMemo} from 'react';
import classNames from 'classnames';
import { sumBeautifier } from "../../SYSTEM/Functions"

import styles from "./EfficiencySpeedometer.module.scss"

import Speedometer from './../../APP/ASSETS/speedometer.png'

export const EfficiencySpeedometer = ({number, title, sum, text, speedTitle, percent}) =>
{
    const deg = useMemo(() =>
    {
        let minimal = -135;
        let maximal = 135;

        let length = 270;
        let change = length / 100 * percent;

        return minimal + change;
    }, [percent])

    return (
        <div className={styles.speedometer}>
            {number && number > 0 &&
                <div className={styles.numberHolder}>
                    {number}
                </div>
            }
            {title && title.length > 0 &&
                <div className={styles.title}>
                    {title}
                </div>
            }
            {sum &&
                <div className={styles.sum}>
                    {sumBeautifier(sum)}
                </div>
            }
            {text &&
                <div className={styles.text}>
                    {text}
                </div>
            }
            <div className={styles.speedometerBody}>
                <img src={Speedometer} />
                <span>
                    {speedTitle ? speedTitle : 'weeks'}
                </span>
                <div className={styles.speedTick} style={{transform: 'rotate(' + deg + 'deg)'}}/>
            </div>
        </div>
    )
}
