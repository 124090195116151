import styles from "./BigImage.module.scss"

export const BigImage = ({src}) =>
{
    if (!src) {
        return false;
    }

    return (
        <div className={styles.bigImage} style={{
            background: 'transparent url(' + src + ')'
        }} />
    );
}