import classNames from 'classnames';
import styles from "./ManagerFilter.module.scss"
import { Avatar } from "./Common/Avatar"

export const ManagerFilter = ({selectedManager, managers, setManager}) =>
{
    const selectManager = (managerId) =>
    {
        if (selectedManager == managerId) {
            setManager(false)
        } else {
            setManager(managerId)
        }
    }

    return (
        <div className={styles.managerFilter}>
            {managers.map((manager, index) => {
                return (
                    <a
                        className={classNames(styles.managerBlock, {[styles.selected]: selectedManager == manager.id})}
                        key={index}
                        onClick={() => selectManager( manager.id)}
                    >
                        <Avatar src={manager.image} size="30px" />
                        <span>{manager.name} {manager.last_name}</span>
                    </a>
                )
            })}
        </div>
    )
}