import React, {Component} from 'react';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import classNames from "classnames"

import InnerLoader from "../COMPONENTS/Common/InnerLoader"
import { CheckboxList } from "../COMPONENTS/Form/CheckboxList"
import { Selectable } from "../COMPONENTS/Form/Selectable"
import { ManagerSelectOption } from "../COMPONENTS/ManagerSelectOption"
import TextInput from "../COMPONENTS/Form/TextInput"
import PhoneInput from "../COMPONENTS/Form/PhoneInput"

import styles from "./Form.module.scss"



/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        EDIT: state.MODALS_LIST.EditShortLink
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class EditShortLink extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            incoming: this.props.EDIT.incoming,
            outgoing: this.props.EDIT.outgoing,
            customer: this.props.EDIT.customer,
            LOADED_MANAGERS: false,
            MANAGERS: []
        }
    }

    componentDidMount = () =>
    {
        this.getCustomers();
    }

    getCustomers = async () =>
    {
        if (!this.fetchingCustomers) {
            this.fetchingCustomers = true;

            try {
                let SEND_DATA = {
                    action:     'getCustomers',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetchingCustomers = false;
                    this.setState({LOADED_CUSTOMERS: true, CUSTOMERS: data})
                } else {
                    this.fetchingCustomers = false;
                    this.setState({LOADED_CUSTOMERS: true, CUSTOMERS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetchingCustomers = false;
            }
        }
    }

    isReadyToSave = () =>
    {
        if (this.state.outgoing.length < 4) {
            return false;
        }

        return true;
    }

    recordValue = (string, key) =>
    {
        this.setState({[key]: string})
    }

    save = async () =>
    {
        if (!this.isReadyToSave()) {
            return;
        }

        if (!this.saving) {
            this.saving = true;

            try {
                let SEND_DATA = {
                    action:     'editShortLink',
                    token:      this.props.USER.token,
                    id:         this.props.EDIT.id,
                    incoming: this.state.incoming,
                    outgoing: this.state.outgoing,
                    comment: this.state.comment,
                    customer: this.state.customer
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.saving = false;
                    this.setState({ERROR: false})
                    this.props.refetch(['ShortLinks']);
                    this.props.toggleModal({key: 'EditShortLink', status: false})
                } else {
                    this.saving = false;
                    this.setState({LOADED: true})
                }
            } catch (error) {
                console.log(error)
                this.saving = false;
            }
        }
    }

    getCustomersList = () =>
    {
        let OPTIONS = []
        for (let i = 0; i < this.state.CUSTOMERS.length; i++) {
            let CUSTOMER = this.state.CUSTOMERS[i]

            OPTIONS.push({
                value: CUSTOMER.id,
                label: CUSTOMER.name
            })
        }

        return OPTIONS;
    }

    render()
    {
        if (!this.state.LOADED_CUSTOMERS) {
            return <InnerLoader />
        }

        return (
			<>
                <div className="row">
                    <div className="seventh">

                        <TextInput
                            name="incoming"
                            value={this.state.incoming}
                            placeholder="Короткая ссылка"
                            type="text"
                            recordValue={this.recordValue}
                        />

                        <TextInput
                            name="outgoing"
                            value={this.state.outgoing}
                            placeholder="Внешняя ссылка"
                            type="text"
                            recordValue={this.recordValue}
                        />

                        <TextInput
                            name="comment"
                            value={this.state.comment}
                            placeholder="Комментарий"
                            type="text"
                            recordValue={this.recordValue}
                        />

                    </div>

                    <div className="third">
                        <Selectable
                            name="customer"
                            value={this.state.customer}
                            placeholder="Проект"
                            recordValue={this.recordValue}
                            options={this.getCustomersList()}
                        />
                    </div>
                </div>

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>Произошла ошибка при сохранении!</div>}
                    <a onClick={() => this.save()} className={classNames(styles.submitButton, {[styles.inactive]: !this.isReadyToSave()})}>Сохранить</a>
                </div>
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(EditShortLink);