import React, {Component} from 'react';
import InputMask from "react-input-mask";

import styles from "./TextInput.module.scss"

export default class PhoneInput extends Component {

	constructor(props)
    {
        super(props);
    }

    componentDidupdate = (prevProps, prevState, snapshot) =>
    {

    }

    handleKeyDown = (e) =>
    {
        if (typeof this.props.handleKeyDown !== "undefined") {
            this.props.handleKeyDown(e);
        }
    }

	render() { 
        return (
            <InputMask
                placeholder={this.props.placeholder}
                type="tel"
                className={styles.input}
                mask="+7-999-999-99-99"
                onChange={(e) => this.props.recordValue(e.target.value, this.props.name)}
                defaultValue={this.props.value}
            />
    	);
	}
}