import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../../SYSTEM/Config.js'
import { Request } from "../../../SYSTEM/Request"

/// REDUX ///
import * as ReduxActions from '../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import { SearchInput } from "./SearchInput"
import { Avatar } from "./Avatar"
import { DropDown } from "./DropDown"

import Down from'./../../../APP/ASSETS/nav/down.svg' 
import Settings from'./../../../APP/ASSETS/settings.svg' 
import Logout from'./../../../APP/ASSETS/logout.svg' 

import styles from "./Header.module.scss"
import classNames from 'classnames';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Header extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            OPENED: false
        }
    }

    opened = (value) =>
    {
        this.setState({OPENED: value})
    }

    render()
    {
        return (
            <div className={styles.headerRow}>
                <div/>

                <div className={styles.rightBlock}>
                    <DropDown
                        toggle={
                            <div className={styles.userData}>
                                <Avatar src={this.props.USER.image} />
                                <span className={styles.userName}>{this.props.USER.name}</span>
                                <img src={Down} className={classNames(styles.angleDown, {[styles.opened]: this.state.OPENED})} />
                            </div>
                        }
                        content={
                            <div className={styles.headerNavs}>
                                <a onClick={() => this.props.toggleModal({key: 'Settings', status: true})}>Настройки <img src={Settings} /></a>
                                <a onClick={() => this.props.logOut()}>Выход <img src={Logout} /></a>
                            </div>
                        }
                        setOpened={this.opened}
                    />
                </div>
            </div>
        )
    }

}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Header);