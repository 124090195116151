import React, {useState, useRef, useEffect} from 'react';
import classNames from 'classnames';

import styles from "./DropDown.module.scss"

export const DropDown = ({toggle, content, setOpened}) =>
{
    const [isVisible, setVisible] = useState(false)
    const ref = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setVisible(false)

                if (setOpened) {
                    setOpened(false)
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref]);

    const doToggle = () =>
    {
        if (isVisible) {
            setVisible(false)

            if (setOpened) {
                setOpened(false)
            }

            return
        }

        setVisible(true)

        if (setOpened) {
            setOpened(true)
        }
    }

    return (
        <div className={styles.dropDown} ref={ref}>
            <div className={styles.dropDownToggle} onClick={doToggle}>
                {toggle}
            </div>
            <div className={classNames(styles.dropDownBody, {[styles.visible]: isVisible})}>
                {content}
            </div>
        </div>
    )
}
