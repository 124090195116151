import React, {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from "react-redux";
import classNames from 'classnames'

import { Avatar } from "./Common/Avatar"
import Pencil from'./../../APP/ASSETS/pencil.svg' 

import styles from "./UsersTable.module.scss"

export const UsersTableRow = ({user}) =>
{
    const dispatch = useDispatch()

    const editUser = () =>
    {
        dispatch({
            type: 'TOGGLE_MODAL',
            data: {
                key: "EditUser",
                status: user
            }
        })
    }

    const userRole = (role) => 
    {
        switch (role) {
            case 2: return 'Офис';
            case 3: return 'Менеджер по продажам';
            case 9: return 'Администратор';
        }

        return 'Unknown';
    }

    return (
        <tr>
            <td>
                <Avatar src={user.image} />
            </td>
            <td>
                {user.name} {user.last_name}
            </td>
            <td className={styles.longCell}>{userRole(user.role)}</td>
            <td><a className={styles.editButton} onClick={() => editUser()}><img src={Pencil} /></a></td>
        </tr>
    )
}
