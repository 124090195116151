import React, {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from "react-redux";
import classNames from 'classnames'

import { sumBeautifier } from "./../../SYSTEM/Functions"

import Pencil from'./../../APP/ASSETS/pencil.svg' 

import styles from "./CustomersTable.module.scss"

export const Td = ({content}) =>
{
    return (
        <td>
            <div className={styles.td}>
                {content && (content.length || content !== '') ? content : '-'}
            </div>
        </td>
    )
}

export const CustomersTableRow = ({customer}) =>
{
    const dispatch = useDispatch()

    const editCustomer = () =>
    {
        dispatch({
            type: 'TOGGLE_MODAL',
            data: {
                key: "EditCustomer",
                status: customer
            }
        })
    }

    const managerContent = useMemo(() =>
    {
        if (customer.manager) {
            return customer.manager.name + ' ' + customer.manager.last_name;
        }

        return false;
    }, [customer])

    const groupsContent = useMemo(() =>
    {
        if (customer.groups && customer.groups.length) {
            let names = customer.groups.map((group, index) => {
                return (
                    <div key={index}>
                        {group.name}
                    </div>
                );
            });
            return names;
        }

        return false;
    }, [customer])

    const lastOrderContent = useMemo(() =>
    {
        if (customer.last_order) {
            return customer.last_order.past_days
        }

        return false;
    }, [customer])

    return (
        <tr>
            <Td
                content={managerContent}
            />
            <Td
                content={customer.name}
            />
            <Td
                content={customer.phone}
            />
            <Td
                content={customer.email}
            />
            <Td
                content={customer.city + ', ' + customer.adress}
            />
            <td><a className={styles.editButton} onClick={() => editCustomer()}><img src={Pencil} /></a></td>
        </tr>
    )
}
