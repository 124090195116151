import {useMemo} from 'react'
import styles from "./VisitLine.module.scss"

const getFieldsList = () =>
{
    let OPTIONS = []
    
    OPTIONS.push({
        value: 'name',
        label: 'Имя'
    })
    OPTIONS.push({
        value: 'last_name',
        label: 'Фамилия'
    })
    OPTIONS.push({
        value: 'middle_name',
        label: 'Отчество'
    })
    OPTIONS.push({
        value: 'comment',
        label: 'Комментарий'
    })
    OPTIONS.push({
        value: 'phone',
        label: 'Телефон'
    })
    OPTIONS.push({
        value: 'email',
        label: 'Email'
    })
    OPTIONS.push({
        value: 'adress',
        label: 'Адрес'
    })
    OPTIONS.push({
        value: 'company',
        label: 'Название компании'
    })
    OPTIONS.push({
        value: 'web',
        label: 'Сайт'
    })
    OPTIONS.push({
        value: 'inn',
        label: 'ИНН'
    })

    return OPTIONS;
}

export const VisitLine = ({data}) =>
{
    const datetimeParts = data.datetime.split(' ');
    const fieldsBlock = useMemo(() => {
        let fieldValues = getFieldsList();

        if (typeof data.fields !== "undefined" && data.fields !== null) {
            let rows = [];

            for (let i = 0; i < fieldValues.length; i++) {
                if (typeof data.fields[fieldValues[i].value] !== "undefined") {
                    rows.push(
                        <div key={i}>
                            <strong>{fieldValues[i].label}</strong>
                            <br/>
                            {data.fields[fieldValues[i].value]}
                        </div>
                    )
                }
            }

            return rows;
        }

        return false;
    }, [data])

    return (
        <div className={styles.visitLine}>
            <div className={styles.datetime}>
                {datetimeParts[0]}
                <br/>
                {datetimeParts[1]}
            </div>
            <div className={styles.ip}>
                {data.ip}
            </div>
            <div className={styles.data}>
                {data.data?.browser ? <div>Browser: {data.data.browser}</div> : null}
                {data.data?.os ? <div>OS: {data.data.os}</div> : null}
                {data.data?.user_agent ? <div>User Agent: {data.data.user_agent}</div> : null}
            </div>
            {fieldsBlock && 
                <div className={styles.fields}>
                    {fieldsBlock}
                </div>
            }
        </div>
    )
}