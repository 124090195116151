
import React, {useState, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import styles from "./StatusLine.module.scss"

export const StatusLine = ({
    value, 
    total, 
    postFix, 
    preFix, 
    baseColor,
    currentColor,
    minimum = false, 
    unlimited = false,
    hideOverflow = false,
    showBaseLabel = true
}) =>
{
    value = parseFloat(value)
    total = parseFloat(total)


    const width = useMemo(() =>
    {
        if (parseInt(value) == 0) {
            return 0;
        }

        if (parseInt(total) == 0) {
            return 0;
        }
        
        if ((total == value) && (total == 0)) {
            return 0;
        }

        if (total == value) {
            return 100;
        }

        let width = Math.round(value / (total / 100));

        if (width > 100 && width > 105) {
            width = 105;
        }

        return width;
    }, [value, total, unlimited])

    const minimumPoint = useMemo(() =>
    {
        if (!minimum || total == 0) {
            return 0;
        }

        return Math.round(minimum / (total / 100));
    }, [minimum, total])

    let totalString = postFix && postFix.length > 0 ? total + ' ' + postFix : total;
    let valueString = postFix && postFix.length > 0 ? value + ' ' + postFix : value;
    totalString = preFix && preFix.length > 0 ? preFix + ' ' + totalString : totalString;
    valueString = preFix && preFix.length > 0 ? preFix + ' ' + valueString : valueString;

    return (
        <div className={styles.lineHolder}>
            {minimumPoint > 0 &&
                <div className={styles.minimumLine} style={{left: 'calc(' + minimumPoint + '% - 1px)'}} />
            }

            <div
                className={classNames( styles.lineBase, {[styles.error]: value == 0} )} 
                style={baseColor && baseColor.length > 0 ? { backgroundColor: baseColor } : {}}
            >
                {parseInt(total) > 0 && showBaseLabel ? totalString : ''}
            </div>

            { width > 10 && width <= 100 &&
                <div 
                    className={
                        classNames(styles.lineForeground, {
                            [styles.error]: width > 100 || minimum && value < minimum
                        })
                    }
                    style={{width: width + '%', backgroundColor: currentColor && currentColor.length > 0 ? currentColor  : ''}}
                >
                    {width <= 100 && parseInt(value) > 0 ? 
                        width > 0 ? 
                            parseInt(value) > parseInt(total) ? 
                                totalString : 
                            valueString : 
                        '' : 
                    ''}
                </div>
            }

            {width > 100 &&
                <div 
                    className={
                        classNames(styles.lineForeground, {
                            [styles.error]: width > 100 || minimum && value < minimum
                        })
                    } 
                    style={{width: width + '%', backgroundColor: currentColor && currentColor.length > 0 ? currentColor  : ''}}
                >
                    {parseInt(value) > 0 ? 
                        width > 0 ? 
                            parseInt(value) > parseInt(total) ? 
                                totalString : 
                            valueString : 
                        '' : 
                    ''}
                </div>
            }
        </div>
    )
}