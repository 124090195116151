import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"

import styles from "./Page.module.scss"

import { MessageBox } from "./../../APP/COMPONENTS/MessageBox"
import { ListTable } from "../COMPONENTS/ListTable/ListTable"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"
import { HeadingButton } from "./../../APP/COMPONENTS/HeadingButton"

import Pencil from'./../../APP/ASSETS/pencil.svg' 

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class ShortLinks extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            LIMIT: 20,
            START: 0,
            DATA: [],
            SHOW_BUTTON: false
        }

        this.data_string = 'ShortLinks';
    }

    componentDidMount = () =>
    {
        this.getCustomers();
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REFETCH !== prevProps.REFETCH) {
            if (this.props.REFETCH && this.props.REFETCH.length) {
                if (this.props.REFETCH.includes(this.data_string)) {
                    this.setState({START: 0, LIMIT: 20, DATA: []}, () => this.fetchData());
                }
            }
        }
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getShortLinks',
                    token:      this.props.USER.token,
                    start:      this.state.START,
                    limit:      this.state.LIMIT
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    let NEW_DATA = this.state.DATA.concat(data.list);
                    this.setState({
                        LOADED: true, 
                        DATA: NEW_DATA, 
                        SHOW_BUTTON: NEW_DATA.length < data.total,
                        START: this.state.START + this.state.LIMIT
                    })
                } else {
                    this.fetching = false;
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    openAddModal = () =>
    {
        this.props.toggleModal({key: 'AddShortLink', status: true})
    }

    editItem = (item) =>
    {
        this.props.toggleModal({
            key: "EditShortLink",
            status: item
        })
    }
    
    viewShortLinkVisits = (item) =>
    {
        this.props.toggleModal({
            key: "ShortLinkVisits",
            status: item
        })
    }

    getCustomerName = (customer) =>
    {
        let customerArray = this.state.CUSTOMERS.filter((customerObj) => {
            return customerObj.id == customer
        })

        return customerArray[0].name;
    }

    renderCell = (item, key) =>
    {
        switch (key) {
            case 'button':
                return <a className={styles.editButton} onClick={() => this.editItem(item)}><img src={Pencil} /></a>;
            case 'customer':
                return item.customer > 0 ? this.getCustomerName(item.customer) : '-';
            case 'incomingLink':
                return <a href={item.incoming_link} target="_blank">{item.incoming}</a>;

            case 'visits':
                return item.visits > 0 ? <a className={styles.link} onClick={() => this.viewShortLinkVisits(item)}>{item.visits}</a> : '-'
        }

        if (typeof item[key] !== "undefined") {
            return item[key];
        }

        return null;
    }

    getCustomers = async () =>
    {
        if (!this.fetching_customers) {
            this.fetching_customers = true;

            try {
                let SEND_DATA = {
                    action:     'getCustomers',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching_customers = false;
                    this.setState({CUSTOMERS_LOADED: true, CUSTOMERS: data})
                } else {
                    this.fetching_customers = false;
                    this.setState({CUSTOMERS_LOADED: true, CUSTOMERS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching_customers = false;
            }
        }
    }

    render() {
        if (!this.props.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>Шорт-линки</title>
                </Helmet>
                <h1>Шорт-линки<HeadingButton action={this.openAddModal}/></h1>

                {!this.state.LOADED && <InnerLoader/>}

                {this.state.LOADED && !this.state.DATA.length && <MessageBox text="No data recieved from server!"/>}

                {this.state.LOADED && this.state.CUSTOMERS_LOADED && this.state.DATA.length && 
                    <ListTable
                        renderCell={this.renderCell}
                        list={this.state.DATA}
                        headings={[
                            {title: 'ID', sortable: false, key: 'id'},
                            {title: 'Проект', sortable: false, key: 'customer'},
                            {title: 'Входящая', sortable: false, key: 'incomingLink'},
                            {title: 'Внешняя ссылка', sortable: false, key: 'outgoing'},
                            {title: 'Комментарий', sortable: false, key: 'comment'},
                            {title: 'Просмотры', sortable: false, key: 'visits'},
                            {title: '', sortable: false, key: 'button', empty: true},
                        ]}
                        showButton={this.state.SHOW_BUTTON}
                        buttonClick={this.fetchData}
                    />
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(ShortLinks))
