import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../../SYSTEM/Config.js'
import { Request } from "../../../SYSTEM/Request"
import classNames from 'classnames';
import { withRouter } from "../../../hooks.js"

import styles from "./LeftNav.module.scss"

/// REDUX ///
import * as ReduxActions from '../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import InnerLoader from './InnerLoader';

import Sales from './../../../APP/ASSETS/nav/sales.svg'
import Orders from './../../../APP/ASSETS/nav/orders.svg'
import Others from './../../../APP/ASSETS/nav/others.svg'
import Materials from './../../../APP/ASSETS/nav/materials.svg'
import Users from './../../../APP/ASSETS/nav/users.svg'
import Efficiency from'./../../../APP/ASSETS/nav/efficiency.svg'
import Down from'./../../../APP/ASSETS/nav/down.svg' 

function mapStateToProps(state) {
    return {
        USER: state.USER,
        PENDING_ORDERS: state.PENDING_ORDERS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class LeftNav extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            TOGGLED: false
        }
    }

    getNavsItemsList = () =>
    {
        return [
            {
                title: 'Шорт-Линки',
                path: '/shortlinks',
                image: Orders,
                children: [],
                roles: []
            },
            {
                title: 'Промокоды',
                path: '/promocodes',
                image: Others,
                children: []
            },
            {
                title: 'Лендинги',
                path: '/landings',
                image: Materials,
                children: []
            },
            {
                title: 'Проекты',
                path: '/customers',
                image: Sales,
                children: [],
                roles: [9]
            },
            {
                title: 'Пользователи',
                path: '/users',
                image: Users,
                children: [],
                roles: [9]
            }
        ];
    }

    checkActiveRoute = (path) =>
    {
        if (path == this.props.location.pathname) {
            return true;
        }

        return false;
    }

    checkActiveChildrenRoute = (index, children) =>
    {
        if (index === this.state.TOGGLED) {
            return true
        }

        let result = false;
        for (let i = 0; i < children.length; i++) {
            if (this.props.location.pathname == children[i].path) {
                result = true;
            }
        }
        
        return result;
    }

    renderNavs = () =>
    {
        let NAVS = this.getNavsItemsList();

        let ITEMS = [];
        for (let i = 0; i < NAVS.length; i++) {
            let ITEM = NAVS[i];

            if (typeof ITEM.roles !== "undefined" && ITEM.roles.length) {
                if (!ITEM.roles.includes(this.props.USER.role)) {
                    continue;
                }
            }

            if (ITEM.path) {
                    ITEMS.push(
                        <Link 
                            key={i} 
                            className={classNames(
                                styles.item,{[styles.active]: this.checkActiveRoute(ITEM.path)}
                            )} 
                            to={ITEM.path}
                        >
                            <img src={ITEM.image} className={styles.svg} />
                            {ITEM.title}
                            {ITEM.children.length > 0 &&
                                <img src={Down} className={styles.angle} />
                            }
                        </Link>
                    );
            } else {
                const visibleSubs = this.checkActiveChildrenRoute(i, ITEM.children);

                ITEMS.push(
                    <a 
                        key={i} 
                        className={classNames(
                            styles.item,{[styles.active]: visibleSubs}
                        )} 
                        onClick={() => this.toggle(i)}
                    >
                        <img src={ITEM.image} className={styles.svg} />
                        {ITEM.title}
                        {ITEM.children.length > 0 &&
                            <img src={Down} className={styles.toggle} />
                        }
                    </a>
                );

                let SUB_MENU = []

                for (let j = 0; j < ITEM.children.length; j++) {
                    let SUB_ITEM = ITEM.children[j];

                    if (typeof SUB_ITEM.roles !== "undefined" && SUB_ITEM.roles.length) {
                        if (!SUB_ITEM.roles.includes(this.props.USER.role)) {
                            continue;
                        }
                    }

                    SUB_MENU.push(
                        <Link to={SUB_ITEM.path} key={j} className={classNames(styles.subLink, {[styles.active]: this.checkActiveRoute(SUB_ITEM.path)})}>{SUB_ITEM.title}</Link>
                    )
                }

                ITEMS.push(
                    <div key={i + '_subs'} className={classNames(styles.subMenu, {[styles.visible]: visibleSubs})}>
                        {SUB_MENU}
                    </div>
                )
            }
        }

        return (
            <div className={styles.leftNav}>
                {ITEMS}
            </div>
        );
    }

    toggle = (index) =>
    {
        let TOGGLED = false;
        if (this.state.TOGGLED !== index) {
            TOGGLED = index;
        }

        this.setState({TOGGLED: TOGGLED})
    }

    render()
    {
        return (
            <div className={styles.leftBar}>
                <Link to="/" className={styles.logoHolder}>
                    <img src="/images/logo.png" className={styles.logo} />
                </Link>

                {this.renderNavs()}
            </div>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftNav))