import React, {useMemo} from 'react';
import classNames from 'classnames'

import { StatusLine } from '../Common/StatusLine'
import { sumBeautifier } from "./../../../SYSTEM/Functions"

import styles from "./GroupRawProducts.module.scss"

export const GroupRawProductRow = ({product}) =>
{
    const toOrder = useMemo(() =>
    {
        let toOrder = parseFloat(product.maximum_amount) + parseFloat(product.amount_in_active_orders) - parseFloat(product.amount);
        if (toOrder < 0) {
            return 0;
        }

        return Math.ceil(toOrder);
    }, [product])

    const error = useMemo(() =>
    {
        return parseFloat(product.amount) < parseFloat(product.minimum_amount) && parseFloat(product.amount) == 0;
    }, [product])

    return (
        <div className={styles.productRow}>
            <div className={styles.productTitle}>
                {product.name}
            </div>
            <div className={classNames( styles.productAmount, {[styles.error]: error} )}>
                {product.amount} {product.unit}
            </div>
            <div className={styles.statusLineHolder}>
                <StatusLine 
                    value={product.amount}
                    total={product.maximum_amount}
                    minimum={product.minimum_amount}
                    postFix={product.unit}
                />
                <StatusLine 
                    value={product.raw_amount_in_active_orders}
                    total={product.maximum_amount}
                    preFix={'orders'}
                    postFix={product.unit}
                    baseColor="white"
                    currentColor="#FFB169"
                    showBaseLabel={false}
                />
            </div>
            <div className={classNames( styles.toOrder, {[styles.error]: error} )}>{toOrder} {product.unit}</div>
            <div className={classNames( styles.toOrder, {[styles.error]: error} )}>{sumBeautifier(product.ware_sum)}</div>
        </div>
    )
}