import React, {useState, useMemo} from 'react';
import classNames from 'classnames';
import InputMask from "react-input-mask";
import CONFIG from '../../SYSTEM/Config.js'

import { Request } from "../../SYSTEM/Request"

import Plus from'../../APP/ASSETS/plus.svg' 

import styles from "./HeadingButton.module.scss"

export const HeadingButton = ({action}) =>
{
    return (
        <a onClick={() => action()} className={styles.button}>
            <img src={Plus} />
            новый
        </a>
    )
}
