import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch} from "react-redux";

import { GroupLeftProductFirstRow } from './GroupLeftProductFirstRow'
import { GroupLeftProductRow } from './GroupLeftProductRow'

import Pencil from'./../../../APP/ASSETS/pencil.svg'


import styles from "./GroupLeftProducts.module.scss"
import { getRandomColor } from '../../../SYSTEM/Functions';

export const GroupLeftProducts = ({group, filter}) =>
{
    const [sort, setSort] = useState(false)
    const dispatch = useDispatch()

    const editGroupPricing = () =>
    {
        dispatch({
            type: 'TOGGLE_MODAL',
            data: {
                key: "EditGroupAmount",
                status: group
            }
        })
    }

    const filteredProducts = useMemo(() =>
    {
        if (filter == 'all') {
            return group.products;
        }

        let products = [];
        group.products.map((product) => {
            if (filter == 'zero') {
                if (parseInt(product.amount) == 0) {
                    products.push(product)
                }
            } else if (filter == 'stock') {
                if (parseInt(product.amount) > 0) {
                    products.push(product)
                }
            }
        });

        return products;
        
    }, [group, filter])

    const sortedProducts = useMemo(() =>
    {
        if (sort == false) {
            return filteredProducts;
        }

        if (sort == 'desc') {
            filteredProducts.sort(function(a, b) {
                return (parseFloat(b.maximum_amount) + parseFloat(b.amount_in_active_orders) - parseFloat(b.amount)) - (parseFloat(a.maximum_amount) + parseFloat(a.amount_in_active_orders) - parseFloat(a.amount))
            });
        }

        if (sort == 'asc') {
            filteredProducts.sort(function(a, b) {
                return (parseFloat(a.maximum_amount) + parseFloat(a.amount_in_active_orders) - parseFloat(a.amount)) - (parseFloat(b.maximum_amount) + parseFloat(b.amount_in_active_orders) - parseFloat(b.amount));
            });
        }

        return filteredProducts;
        
    }, [filteredProducts, sort])

    return (
        <div className={styles.groupRow}>
            <a className={styles.editButton} onClick={() => editGroupPricing()}><img src={Pencil} /></a>
            <div className={styles.groupHeading} style={{borderColor: group.color.length > 0 ? group.color : getRandomColor()}}>
                {group.name}               
            </div>
            <div className={styles.productsList}>
                <GroupLeftProductFirstRow 
                    sort={sort}
                    setSort={setSort}
                />
                {sortedProducts.map((product, index) => {
                    return (
                        <GroupLeftProductRow 
                            key={index}
                            product={product}
                        />
                    )
                })}
            </div>           
        </div>
    )
}
