import React, {useState, useEffect, useMemo} from 'react';
import { sumBeautifier } from "../../../SYSTEM/Functions"
import classNames from 'classnames';

import styles from "./ListTable.module.scss"

export const Td = ({content, empty}) =>
{
    return (
        <td>
            {
                empty 
                ?
                    content && (content.length || content !== '') ? content : '-'
                :
                <div className={styles.td}>
                    {content && (content.length || content !== '') ? content : '-'}
                </div>
            }
        </td>
    )
}

export const ListTableRow = ({item, headings, renderCell}) =>
{
    const row = headings.map((heading, index) => {
        return (
            <Td
                key={index}
                content={renderCell(item, heading.key)}
                empty={heading.empty}
            />
        )
    })

    return (
        <tr>
            {row}
        </tr>
    )
}