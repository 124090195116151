import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import classNames from "classnames"

import { Checkbox } from '../COMPONENTS/Form/Checkbox';
import TextInput from "../COMPONENTS/Form/TextInput"

import styles from "./Form.module.scss"

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state) {
    return {
        USER: state.USER,
        EDIT: state.MODALS_LIST.EditGroupPricing
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class EditGroupPricing extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            products: this.getProducts(),
            price: this.props.EDIT.price,
            showInactive: false
        }
    }

    getProducts = () =>
    {
        let products = {};
        for (let i = 0; i < this.props.EDIT.products.length; i++) {
            let product = this.props.EDIT.products[i]
            products[product.id] = {
                id: product.id,
                name: product.name,
                price_formula: product.price_formula,
                active: product.active
            }
        }
        return products;
    }

    recordPriceFormula = (price_formula, key) =>
    {
        let products = Object.assign({},{...this.state.products});
        products[key].price_formula = price_formula;
        this.setState({products: products})
    }

    switchActivity = (checked, key) =>
    {
        let products = Object.assign({},{...this.state.products});
        products[key].active = !products[key].active;
        this.setState({products: products})
    }

    usePriceCountFomula = (formula, price) =>
    {
        if (formula.length) {
            let formulaParts = formula.split('P');
            formula = formulaParts.join(price)
            try {
                return eval(formula).toFixed(2);
            } catch (error) {
                console.log(error)
                return 'ERROR'
            }
        }

        return price;
    }

    renderRows = () =>
    {
        let rows = [];

        for (let i in this.state.products) {
            let product = this.state.products[i]
            
            if (product.active || this.state.showInactive) {
                rows.push(
                    <div className={styles.flexInlineRow} key={i}>
                        <div className={styles.inlineUnderlinedRow}>
                            <Checkbox
                                name={product.id}
                                value={product.active}
                                recordValue={this.switchActivity}
                            />
                            <div className={styles.inlineTitle}>
                                {product.name}
                            </div>
                        </div>
                        <div className={styles.inlineInput}>
                            <TextInput
                                name={product.id}
                                value={product.price_formula}
                                placeholder=""
                                type="text"
                                recordValue={this.recordPriceFormula}
                                inlineInput={true}
                            />
                            <span>{this.usePriceCountFomula(product.price_formula,this.state.price)}$</span>
                        </div>
                    </div>
                )
            }
        }

        return rows;
    }

    save = async () =>
    {
        if (!this.saving) {
            this.saving = true;

            try {
                let SEND_DATA = {
                    action:     'editProductsPriceFormulas',
                    token:      this.props.USER.token,
                    products:   this.state.products,
                    price:      this.state.price,
                    id:         this.props.EDIT.id
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.saving = false;
                    this.setState({ERROR: false})
                    this.props.refetch(['TodayPricing']);
                    this.props.toggleModal({key: 'EditGroupPricing', status: false})
                } else {
                    this.saving = false;
                    this.setState({LOADED: true})
                }
            } catch (error) {
                console.log(error)
                this.saving = false;
            }
        }
    }

    changeVisibility = (value) =>
    {
        console.log(value)
        this.setState({showInactive: value})
    }

    render()
    {
        return (
			<>
                <div className={styles.flexRow}>
                    <div className={classNames(styles.header, styles.spaceBetween)}>
                        {this.props.EDIT.name}
                        <div className={styles.inlineInput}>
                            <input type="number" className={styles.headerInput} defaultValue={this.state.price} onChange={(input) => this.setState({price: input.target.value})} />
                            </div>
                    </div>
                </div>

                <Checkbox
                    recordValue={this.changeVisibility}
                    placeholder="Show hidden"
                    value={this.state.showInactive}
                />

                {this.renderRows()}

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>Error occured while saving data!</div>}
                    <a onClick={() => this.save()} className={styles.submitButton}>Save changes</a>
                </div>
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(EditGroupPricing);