import React, {useMemo} from 'react';
import classNames from 'classnames'

import { StatusLine } from '../Common/StatusLine'

import styles from "./GroupLeftProducts.module.scss"

export const GroupLeftProductRow = ({product}) =>
{
    const toMake = useMemo(() =>
    {
        let toMake = parseFloat(product.maximum_amount) + parseFloat(product.amount_in_active_orders) - parseFloat(product.amount);
        if (toMake < 0) {
            return 0;
        }

        return Math.ceil(toMake);
    }, [product])

    const error = useMemo(() =>
    {
        return parseFloat(product.amount) < parseFloat(product.minimum_amount) && parseFloat(product.amount) == 0;
    }, [product])

    return (
        <div className={styles.productRow}>
            <div className={styles.productTitle}>
                {product.name}
            </div>
            <div className={classNames( styles.productAmount, {[styles.error]: error} )}>
                {product.amount} {product.unit}
            </div>
            <div className={styles.statusLineHolder}>
                <StatusLine 
                    value={product.amount - product.reserved}
                    total={product.maximum_amount}
                    minimum={product.minimum_amount}
                    postFix={product.unit}
                />
            </div>
            <div className={classNames( styles.plusOrder, {[styles.error]: error} )}>{product.amount_in_active_orders} {product.unit}</div>
            <div className={classNames( styles.toMake, {[styles.error]: error} )}>{toMake} {product.unit}</div>
        </div>
    )
}