import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"

import classNames from "classnames"

import TextInput from "./../../APP/COMPONENTS/Form/TextInput"
import PhoneInput from "./../../APP/COMPONENTS/Form/PhoneInput"
import { Avatar } from "./../../APP/COMPONENTS/Common/Avatar"

import Plus from'./../../APP/ASSETS/plus.svg' 
import Lock from'./../../APP/ASSETS/lock.svg' 

import styles from "./Form.module.scss"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Settings extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            name: this.props.USER.name,
            login: this.props.USER.login,
            last_name: this.props.USER.last_name,
            email: this.props.USER.email,
            phone: this.props.USER.phone,
            avatar: false,

            changePassword: false,
            old_password: '',
            new_password: '',
            new_password2: ''            
        }
    }

    isReadyToSave = () =>
    {
        if (this.state.changePassword) {
            if (this.state.old_password.length < 3) {
                return false;
            }

            if (this.state.new_password.length < 3 || this.state.new_password !== this.state.new_password2) {
                return false;
            }
        }

        if (this.state.name.length < 2 || this.state.login.length < 2 ) {
            return false;
        }

        let phoneNumber = this.state.phone.toString().replace(/\D+/g, '');
        if (phoneNumber.length !== 11) {
            return false;
        }

        return true;
    }

    recordValue = (string, key) =>
    {
        this.setState({[key]: string})
    }

    renderAvatarLoader = () =>
    {
        let AvatarBlock = (
            <div className={styles.avatarPlaceholder}>
                Фото
            </div>
        )
        
        if (this.props.USER.image || this.state.avatar) {
            let preview = false;
            if (this.state.avatar) {
                preview = URL.createObjectURL(this.state.avatar);
            }

            AvatarBlock = (
                <Avatar
                    src={preview ? preview : this.props.USER.image}
                    size="77px"
                />
            )
        }

        return (
            <label className={styles.avatarChange}>
                {AvatarBlock}
                <img src={Plus} className={styles.plusButton} />
                <input style={{'display':'none'}} type="file" onChange={(e) => this.recordValue(e.target.files[0], 'avatar')} accept="image/*" />
            </label>
        )
    }

    save = async () =>
    {
        if (!this.isReadyToSave()) {
            return;
        }

        if (!this.saving) {
            this.saving = true;

            let SEND_DATA = new FormData();

            SEND_DATA.append('action', 'editSelf');
            SEND_DATA.append('token', this.props.USER.token);
            SEND_DATA.append('avatar', this.state.avatar);
            SEND_DATA.append('id', this.props.USER.id);
            SEND_DATA.append('name', this.state.name);
            SEND_DATA.append('last_name', this.state.last_name);
            SEND_DATA.append('phone', this.state.phone);
            SEND_DATA.append('email', this.state.email);
            SEND_DATA.append('login', this.state.login);
            SEND_DATA.append('changePassword', this.state.changePassword);
            SEND_DATA.append('old_password', this.state.old_password);
            SEND_DATA.append('new_password', this.state.new_password);
            SEND_DATA.append('new_password2', this.state.new_password2);

            fetch( 
                CONFIG.API_GATE, 
                {
                    credentials: 'include',
                    method: "POST",
                    body: SEND_DATA
                }
            )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status.result == 'SUCCESS') {
                    this.setState({ERROR: false})
                    this.props.doAuth(responseJson.data)
                } else {
                    this.setState({ERROR: true})
                }

                this.saving = false;
            })
            .catch(err => {
                console.log(err)
                this.saving = false;
                this.setState({ERROR: true})
            })
        }
    }

    render()
    {

        return (
			<>
                <div className="row">
                    <div className="third">
                        <TextInput
                            name="name"
                            value={this.state.name}
                            placeholder="Имя"
                            type="text"
                            recordValue={this.recordValue}
                        />
                        <PhoneInput
                            name="phone"
                            value={this.state.phone}
                            placeholder="Телефон"
                            recordValue={this.recordValue}
                        />
                    </div>
                    <div className="third">
                        <TextInput
                            name="last_name"
                            value={this.state.last_name}
                            placeholder="Фамилия"
                            type="text"
                            recordValue={this.recordValue}
                        />
                        <TextInput
                            name="email"
                            value={this.state.email}
                            placeholder="Email"
                            type="email"
                            recordValue={this.recordValue}
                        />
                    </div>
                    <div className="third">
                        {this.renderAvatarLoader()}
                    </div>
                </div>

                <div style={{height: '50px'}} />

                <div className={styles.header}>
                    <img src={Lock} /> Настройки доступа
                </div>

                <div className="row">
                    <div className="third">
                        <TextInput
                            name="login"
                            value={this.state.login}
                            placeholder="Логи"
                            type="text"
                            recordValue={this.recordValue}
                        />
                    </div>
                </div>

                <a className={styles.flatLink} onClick={() => this.setState({changePassword: !this.state.changePassword})}>Изменить пароль</a>

                {
                    this.state.changePassword &&
                    <div className="row">
                        <div className="third">
                            <TextInput
                                name="old_password"
                                value=""
                                placeholder="Старый папроль"
                                type="password"
                                recordValue={this.recordValue}
                            />
                        </div>
                        <div className="third">
                            <TextInput
                                name="new_password"
                                value=""
                                placeholder="Новый пароль"
                                type="password"
                                recordValue={this.recordValue}
                            />
                        </div>
                        <div className="third">
                            <TextInput
                                name="new_password2"
                                value=""
                                placeholder="Повторите новый пароль"
                                type="password"
                                recordValue={this.recordValue}
                            />
                        </div>
                    </div>
                }

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>При сохранении произошла ошибка!</div>}
                    <a onClick={() => this.save()} className={classNames(styles.submitButton, {[styles.inactive]: !this.isReadyToSave()})}>Сохранить</a>
                </div>
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Settings);