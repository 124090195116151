import React, {useState, useMemo} from 'react';
import classNames from 'classnames';
import InputMask from "react-input-mask";
import CONFIG from './../../../SYSTEM/Config.js'

import { Request } from "./../../../SYSTEM/Request"

import styles from "./../GuestHomepage.module.scss"

export const CodeForm = ({switchView, doLogin, phone}) =>
{
    const [code, recordCode] = useState(false)
    const [error, setError] = useState(false)
    const [sending, startSendingData] = useState(false)

    const isReadyToEnterCode = useMemo(() =>
    {
        if (code && code.length == 4) {
            return true;
        }

        return false
    }
    ,[code])

    const submitForm = async () =>
    {
        if (!isReadyToEnterCode || sending) {
            return
        }

        setError(false)
        startSendingData(true)

        try {
            let SEND_DATA = {
                action:     'enterCode',
                phone:      phone,
                code:       code
            };

            let data = await Request(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                startSendingData(false)
                doLogin(data)
            } else {
                setError(true)
                startSendingData(false)
            }
        } catch (error) {
            console.log(error)
            startSendingData(false)
        }
    }

    const handleKeyPress = (e) =>
    {
        if (e.key === 'Enter') {
			submitForm()
		}
    }

    return (
        <>
            <div className={styles.whatsappText}>
                Мы отправили код на номер {phone}
                <br/>
                Укажите его ниже
            </div>
            <div className={styles.inputsHolder}>
                <input
                    placeholder="XXXX"
                    type="tel"
                    className={classNames(styles.codeInput, {[styles.error]: error})}
                    onChange={(e) => recordCode(e.target.value.toString().slice(0,4))}
                    autoFocus={true}
                    onKeyDown={handleKeyPress}
                    max="9999"
                    value={code ? code : ''}
                />
                {
                    error && <div className={styles.errorText}>код не правильный</div>
                }
            </div>

            <a 
                onClick={submitForm}
                className={
                    classNames(
                        styles.loginButton,  styles.marginTop, {
                            [styles.inactive]: !isReadyToEnterCode
                        }
                    )
                }
            >
                Продолжить
            </a>
        </>
    )
}
