import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"

import styles from "./Page.module.scss"

import { FilterBar } from "./../../APP/COMPONENTS/FilterBar"
import { GroupRawProducts } from "./../../APP/COMPONENTS/GroupRawProducts/GroupRawProducts"
import { MessageBox } from "./../../APP/COMPONENTS/MessageBox"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class RawMaterials extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            FILTER: 'all'
        }

        this.data_string = 'RawMaterials';
    }

    componentDidMount = () =>
    {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REFETCH !== prevProps.REFETCH) {
            if (this.props.REFETCH && this.props.REFETCH.length) {
                if (this.props.REFETCH.includes(this.data_string)) {
                    this.fetchData();
                }
            }
        }
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getRawMaterials',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    useFilter = (value) =>
    {
        if (value !== this.state.FILTER) {
            this.setState({FILTER: value});
        }
    }

    render() {
        if (!this.props.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>Raw materials</title>
                </Helmet>
                <h1>Raw materials</h1>

                {!this.state.LOADED && <InnerLoader/>}

                {this.state.LOADED && !this.state.DATA.length && <MessageBox text="No data recieved from server!"/>}

                {this.state.LOADED && this.state.DATA.length && 
                    <>
                        <FilterBar
                            options={[
                                {key: 'all', title: 'Show all'},
                                {key: 'zero', title: 'Zero left'},
                                {key: 'stock', title: 'Only in stock'}
                            ]}
                            selected={this.state.FILTER}
                            select={this.useFilter}
                        />

                        {this.state.DATA.map((group, index) => {
                            return (
                                <GroupRawProducts
                                    key={index}
                                    group={group}
                                    filter={this.state.FILTER}
                                />
                            )
                        })}
                    </>
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(RawMaterials))
