import React, {useState, useEffect} from 'react';
import classNames from 'classnames'

import styles from "./MessageBox.module.scss"

export const MessageBox = ({text}) =>
{
    return (
        <div className={styles.messageBox}>
            {text}
        </div>
    )
}
