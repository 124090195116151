import React, {Component} from 'react';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import classNames from "classnames"

import InnerLoader from "../COMPONENTS/Common/InnerLoader"
import { CheckboxList } from "../COMPONENTS/Form/CheckboxList"
import { Selectable } from "../COMPONENTS/Form/Selectable"
import { ImageBlock } from "../COMPONENTS/Common/ImageBlock"
import TextInput from "../COMPONENTS/Form/TextInput"
import { RichEditor } from "../COMPONENTS/Form/RichEditor"

import styles from "./Form.module.scss"

import Plus from'./../../APP/ASSETS/plus.svg' 

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class AddLanding extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            title: '',
            description: '',
            button: '',
            button: 'comment',
            image: false,
            customer: false,
            background: '#FFFFFF',
            fields: [],
            LOADED_MANAGERS: false,
            MANAGERS: []
        }
    }

    componentDidMount = () =>
    {
        this.getCustomers();
    }

    getCustomers = async () =>
    {
        if (!this.fetchingCustomers) {
            this.fetchingCustomers = true;

            try {
                let SEND_DATA = {
                    action:     'getCustomers',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetchingCustomers = false;
                    this.setState({LOADED_CUSTOMERS: true, CUSTOMERS: data})
                } else {
                    this.fetchingCustomers = false;
                    this.setState({LOADED_CUSTOMERS: true, CUSTOMERS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetchingCustomers = false;
            }
        }
    }

    isReadyToSave = () =>
    {
        if (this.state.title.length < 4 || this.state.description.length < 4 || this.state.fields.length == 0) {
            return false;
        }

        return true;
    }

    recordValue = (string, key) =>
    {
        this.setState({[key]: string})
    }

    renderImageLoader = () =>
    {
        let ImageLoader = (
            <div className={styles.imagePlaceholder}>
                Фото
            </div>
        )
        
        if (this.state.image) {
            let preview = URL.createObjectURL(this.state.image);

            ImageLoader = (
                <ImageBlock
                    src={preview}
                />
            )
        }

        return (
            <label className={styles.imageChange}>
                {ImageLoader}
                <img src={Plus} className={styles.plusButton} />
                <input style={{'display':'none'}} type="file" onChange={(e) => this.recordValue(e.target.files[0], 'image')} accept="image/*" />
            </label>
        )
    }

    save = async () =>
    {
        if (!this.isReadyToSave()) {
            return;
        }

        if (!this.saving) {
            this.saving = true;

            try {
                let SEND_DATA = new FormData();

                SEND_DATA.append('action', 'createLanding');
                SEND_DATA.append('token', this.props.USER.token);
                SEND_DATA.append('image', this.state.image);
                SEND_DATA.append('title', this.state.title);
                SEND_DATA.append('background', this.state.background);
                SEND_DATA.append('description', this.state.description);
                SEND_DATA.append('customer', this.state.customer);
                SEND_DATA.append('button', this.state.button);
                SEND_DATA.append('comment', this.state.comment);
                for (let i = 0; i < this.state.fields.length; i++) {
                    SEND_DATA.append('fields[' + i + ']', this.state.fields[i]);
                }                

                fetch( 
                    CONFIG.API_GATE, 
                    {
                        credentials: 'include',
                        method: "POST",
                        body: SEND_DATA
                    }
                )
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status.result == 'SUCCESS') {
                        this.saving = false;
                        this.setState({ERROR: false})
                        this.props.refetch(['Landings']);
                        this.props.toggleModal({key: 'AddLanding', status: false})
                    } else {
                        this.setState({ERROR: true})
                    }

                    this.saving = false;
                })
                .catch(err => {
                    console.log(err)
                    this.saving = false;
                    this.setState({ERROR: true})
                })

            } catch (error) {
                console.log(error)
                this.saving = false;
            }
        }
    }

    getCustomersList = () =>
    {
        let OPTIONS = []
        for (let i = 0; i < this.state.CUSTOMERS.length; i++) {
            let CUSTOMER = this.state.CUSTOMERS[i]

            OPTIONS.push({
                value: CUSTOMER.id,
                label: CUSTOMER.name
            })
        }

        return OPTIONS;
    }

    getFieldsList = () =>
    {
        let OPTIONS = []
        
        OPTIONS.push({
            value: 'name',
            label: 'Имя'
        })
        OPTIONS.push({
            value: 'last_name',
            label: 'Фамилия'
        })
        OPTIONS.push({
            value: 'middle_name',
            label: 'Отчество'
        })
        OPTIONS.push({
            value: 'comment',
            label: 'Комментарий'
        })
        OPTIONS.push({
            value: 'phone',
            label: 'Телефон'
        })
        OPTIONS.push({
            value: 'email',
            label: 'Email'
        })
        OPTIONS.push({
            value: 'adress',
            label: 'Адрес'
        })
        OPTIONS.push({
            value: 'company',
            label: 'Название компании'
        })
        OPTIONS.push({
            value: 'web',
            label: 'Сайт'
        })
        OPTIONS.push({
            value: 'inn',
            label: 'ИНН'
        })

        return OPTIONS;
    }

    render()
    {
        if (!this.state.LOADED_CUSTOMERS) {
            return <InnerLoader />
        }

        return (
			<>
                <div className="row">
                    <div className="seventh">

                        {this.renderImageLoader()}

                        <TextInput
                            name="title"
                            value={this.state.title}
                            placeholder="Заголовок"
                            type="text"
                            recordValue={this.recordValue}
                        />

                        <TextInput
                            name="comment"
                            value={this.state.comment}
                            placeholder="Комментарий"
                            type="text"
                            recordValue={this.recordValue}
                        />

                        <TextInput
                            name="background"
                            value={this.state.background}
                            placeholder="Цвет фона"
                            type="color"
                            recordValue={this.recordValue}
                        />

                        <RichEditor
                            name="description"
                            value={this.state.description}
                            placeholder="Внешняя ссылка"
                            type="text"
                            recordValue={this.recordValue}
                        />

                    </div>

                    <div className="third">
                        <CheckboxList
                            name="fields"
                            value={this.state.fields}
                            placeholder=""
                            recordValue={this.recordValue}
                            options={this.getFieldsList()}

                        />

                        <TextInput
                            name="button"
                            value={this.state.button}
                            placeholder="Текст кнопки"
                            type="text"
                            recordValue={this.recordValue}
                        />

                        <Selectable
                            name="customer"
                            value={this.state.customer}
                            placeholder="Проект"
                            recordValue={this.recordValue}
                            options={this.getCustomersList()}
                        />
                    </div>
                </div>

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>Произошла ошибка при сохранении!</div>}
                    <a onClick={() => this.save()} className={classNames(styles.submitButton, {[styles.inactive]: !this.isReadyToSave()})}>Создать</a>
                </div>
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(AddLanding);