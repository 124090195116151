import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import UserHomepage from './../../APP/ROUTES/UserHomepage';
import GuestHomepage from './../../APP/ROUTES/GuestHomepage';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class HomePage extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            USER: this.props.USER
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.USER !== prevProps.USER) {
            this.setState({USER: this.props.USER});
        }
    }

	render() { 
        if (this.state.USER) {
            return <UserHomepage />
        } else {
            return <GuestHomepage />
        }
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(HomePage))
