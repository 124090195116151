import React, {Component, useMemo} from 'react';

import { EfficiencySpeedometer } from "./EfficiencySpeedometer"
import { Avatar } from "./Common/Avatar"
import { sumBeautifier } from "./../../SYSTEM/Functions"

import styles from "./ActiveOrdersColumns.module.scss"

const SaleCard = ({number, sale}) =>
{
    return (
        <div className={styles.saleCard}>
            <div className={styles.firstRow}>
                <span>{number}</span>
                <div>
                    {sale.customer}
                </div>
            </div>
            
            {sale.products.map((product, index) => {
                return (
                    <div key={index} className={styles.secondRow}>
                        {product.name}
                        <br/>
                        Amount: {product.amount} {product.unit}
                    </div>
                )
            })}

            <div className={styles.thirdRow}>
                <div>Deadline {sale.deadline}</div>
                <div>Manager: <Avatar src={sale.image} size="16px"/> {sale?.manager?.name}</div>
            </div>
        </div>
    )
}

export const ActiveOrdersColumns = ({data}) =>
{

    return (
        <div className={styles.wrapper}>
            <div className={styles.columnsHolder}>
                {data.lines.map((line, index) => {
                    if (line.statistics.orders == 0) {
                        return null;
                    }

                    return (
                        <div key={index} className={styles.operationalColumn}>
                            <EfficiencySpeedometer
                                number={line.id}
                                title={line.title}
                                sum={line.statistics.sales}
                                text={line.statistics.orders + ' orders'}
                                percent={line.statistics.loaded}
                                speedTitle="load"
                            />
                        </div>
                    )
                })}
                {data.unusedSales.length &&
                    <div className={styles.operationalColumn}>
                        <h3 className={styles.notInSystem}>
                            Not in system
                        </h3>
                    </div>
                }
            </div>
            <div className={styles.columnsHolder}>
                {data.lines.map((line, index) => {
                    if (line.statistics.orders == 0) {
                        return null;
                    }

                    return (
                        <div key={index} className={styles.operationalColumn}>
                            <div>
                                {line.sales.map((sale,index) => {
                                    return (
                                        <SaleCard
                                            key={index}
                                            number={index + 1}
                                            sale={sale}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
                <div className={styles.operationalColumn}>
                    {data.unusedSales.length &&
                        data.unusedSales.map((sale,index) => {
                            return (
                                <SaleCard
                                    key={index}
                                    number={index + 1}
                                    sale={sale}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}