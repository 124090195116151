import React, {Component} from 'react';
import {Switch, Route, Navigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { withRouter } from "./../../hooks.js"

import { Request } from "./../../SYSTEM/Request"

import { SalesChart } from "../COMPONENTS/Charts/SalesChart"

import styles from "./Page.module.scss"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class UserHomepage extends Component {
	constructor(props)
    {
        super(props);
        this.state = {
            USER: this.props.USER
        }
    }

    componentDidMount()
    {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {

        if (this.props.USER !== prevProps.USER) {
            this.setState({USER: this.props.USER});
        }
    }

    fetchData = async () =>
    {
        return;
        
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getLastSixYearsStatistics',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    render() {
        if (!this.state.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>CRM</title>
                </Helmet>
                <h1>CRM</h1>
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps, mapDispatchToProps
)(UserHomepage))
