import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../SYSTEM/Config.js'
import { Request } from "../../SYSTEM/Request"

import { sumBeautifier } from "../../SYSTEM/Functions"

import classNames from "classnames"

import InnerLoader from "../COMPONENTS/Common/InnerLoader"
import { CheckboxList } from "../COMPONENTS/Form/CheckboxList"
import { Selectable } from "../COMPONENTS/Form/Selectable"
import { ManagerSelectOption } from "../COMPONENTS/ManagerSelectOption"
import TextInput from "../COMPONENTS/Form/TextInput"
import PhoneInput from "../COMPONENTS/Form/PhoneInput"

import geoUrl from "./../../APP/ASSETS/StatesGeo.json";

import styles from "./Form.module.scss"

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        EDIT: state.MODALS_LIST.EditCustomer
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class EditCustomer extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            name: this.props.EDIT.name,
            state: this.props.EDIT.state,
            adress: this.props.EDIT.adress,
            city: this.props.EDIT.city,
            phone: this.props.EDIT.phone,
            email: this.props.EDIT.email,
            manager: this.props.EDIT.user,
            web: this.props.EDIT.web,
            contact: this.props.EDIT.contact,
            comment: this.props.EDIT.comment,
            groups: this.props.EDIT.groups ? this.props.EDIT.groups.map((group) => { return group.id }) : [],
            
            LAODED_GROUPS: false,
            GROUPS: [],
            LOADED_MANAGERS: false,
            MANAGERS: []
        }
    }

    componentDidMount = () =>
    {
        this.getGroups();
        this.getManagers();
    }

    getGroups = async () =>
    {
        if (!this.fetchingGroups) {
            this.fetchingGroups = true;

            try {
                let SEND_DATA = {
                    action:     'getGroups',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetchingGroups = false;
                    this.setState({LOADED_GROUPS: true, GROUPS: data})
                } else {
                    this.fetchingGroups = false;
                    this.setState({LOADED_GROUPS: true, GROUPS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetchingGroups = false;
            }
        }
    }

    getManagers = async () =>
    {
        if (!this.fetchingManagers) {
            this.fetchingManagers = true;

            try {
                let SEND_DATA = {
                    action:     'getAllManagers',
                    token:      this.props.USER.token
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetchingManagers = false;
                    this.setState({LOADED_MANAGERS: true, MANAGERS: data})
                } else {
                    this.fetchingManagers = false;
                    this.setState({LOADED_MANAGERS: true, MANAGERS: []})
                }
            } catch (error) {
                console.log(error)
                this.fetchingManagers = false;
            }
        }
    }

    isReadyToSave = () =>
    {
        if (this.state.name.length < 2) {
            return false;
        }

        return true;
    }

    recordValue = (string, key) =>
    {
        this.setState({[key]: string})
    }

    save = async () =>
    {
        if (!this.isReadyToSave()) {
            return;
        }

        if (!this.saving) {
            this.saving = true;

            try {
                let SEND_DATA = {
                    action:     'editCustomer',
                    token:      this.props.USER.token,
                    id: this.props.EDIT.id,
                    state: this.state.state,
                    name: this.state.name,
                    adress: this.state.adress,
                    city: this.state.city,
                    phone: this.state.phone,
                    email: this.state.email,
                    web: this.state.web,
                    contact: this.state.contact,
                    manager: this.state.manager,
                    comment: this.state.comment,
                    groups: this.state.groups
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.saving = false;
                    this.setState({ERROR: false})
                    this.props.refetch(['Customers']);
                    this.props.toggleModal({key: 'EditCustomer', status: false})
                } else {
                    this.saving = false;
                    this.setState({LOADED: true})
                }
            } catch (error) {
                console.log(error)
                this.saving = false;
            }
        }
    }

    getGroupsList = () =>
    {
        let OPTIONS = []
        for (let i = 0; i < this.state.GROUPS.length; i++) {
            let GROUP = this.state.GROUPS[i]

            OPTIONS.push({
                value: GROUP.id,
                label: GROUP.name
            })
        }

        return OPTIONS;
    }

    getManagersList = () =>
    {
        let OPTIONS = []
        for (let i = 0; i < this.state.MANAGERS.length; i++) {
            let MANAGER = this.state.MANAGERS[i]

            OPTIONS.push({
                value: MANAGER.id,
                label: <ManagerSelectOption manager={MANAGER} />
            })
        }

        return OPTIONS;
    }

    getStatesList = () =>
    {
        let OPTIONS = [];
        for (let i = 0; i < geoUrl.objects.states.geometries.length; i++) {
            let state = geoUrl.objects.states.geometries[i];
            OPTIONS.push({
                value: state.id,
                label: state.properties.name
            })
        }

        return OPTIONS;
    }

    render()
    {
        if (!this.state.LOADED_GROUPS || !this.state.LOADED_MANAGERS) {
            return <InnerLoader />
        }

        return (
			<>
                <div className="row">
                    <div className="seventh">

                        <TextInput
                            name="name"
                            value={this.state.name}
                            placeholder="Название"
                            type="text"
                            recordValue={this.recordValue}
                        />

                        <div className="row">
                            <div className="half">
                                <TextInput
                                    name="city"
                                    value={this.state.city}
                                    placeholder="Город"
                                    type="text"
                                    recordValue={this.recordValue}
                                />
                            </div>
                            <div className="half">
                                <TextInput
                                    name="adress"
                                    value={this.state.adress}
                                    placeholder="Адрес"
                                    type="text"
                                    recordValue={this.recordValue}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="half">
                                <TextInput
                                    name="web"
                                    value={this.state.web}
                                    placeholder="Сайт"
                                    type="text"
                                    recordValue={this.recordValue}
                                />
                            </div>
                            <div className="half">
                                <TextInput
                                    name="email"
                                    value={this.state.email}
                                    placeholder="Email"
                                    type="text"
                                    recordValue={this.recordValue}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="half">
                                <PhoneInput
                                    name="phone"
                                    value={this.state.phone}
                                    placeholder="Телефон"
                                    recordValue={this.recordValue}
                                />
                            </div>
                            <div className="half">
                                <TextInput
                                    name="contact"
                                    value={this.state.contact}
                                    placeholder="Контакт"
                                    type="text"
                                    recordValue={this.recordValue}
                                />
                            </div>
                        </div>

                        <TextInput
                            name="comment"
                            value={this.state.comment}
                            placeholder="Комментарий"
                            type="text"
                            recordValue={this.recordValue}
                        />

                    </div>

                    <div className="third">
                        <Selectable
                            name="manager"
                            value={this.state.manager}
                            placeholder="Менеджер"
                            recordValue={this.recordValue}
                            options={this.getManagersList()}
                        />
                    </div>
                </div>

                <div className={styles.footer}>
                    {this.state.ERROR && <div className={styles.formError}>Ошибка при редактировании!</div>}
                    <a onClick={() => this.save()} className={classNames(styles.submitButton, {[styles.inactive]: !this.isReadyToSave()})}>Сохранить изменения</a>
                </div>
			</>
		)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(EditCustomer);