import React, {Component, useMemo} from 'react';
import Select from 'react-select'

import styles from "./CheckboxList.module.scss"

const Checkbox = ({change, value, option}) =>
{
    const checked = useMemo(() => {
        return value.includes(option.value);
    }, [option, value])

    return (
        <>
            <input type="checkbox" id={option.value} checked={checked} onChange={(e) => change(!checked, option.value)} />
            <label htmlFor={option.value}><span>{option.label}</span></label>
        </>
    )
}

export const CheckboxList = ({...props}) => 
{
    const change = (checked, checkedValue) =>
    {
        let newValue = props.value;
        if (checked) {
            newValue.push(checkedValue)
        } else {
            newValue = props.value.filter((thisValue) => {
                if (thisValue == checkedValue) {
                    return false
                }

                return true
            })
        }

        props.recordValue(newValue, props.name)
    }

    return (
        <div className={styles.checkboxList}>
            {props.placeholder && props.placeholder.length > 0 &&
                <div className={styles.placeholder}>
                    {props.placeholder}
                </div>
            }
            <div className={styles.checkboxes}>
                {props.options.map((option, index) => {
                    return <Checkbox key={index} change={change} value={props.value} option={option} />
                })}
            </div>
        </div>
    );
}