/// CONFIG ///

const CONFIG = {
    APP_TITLE:      'PL CRM',
    API_IP: 		'',
    API_DOMAIN: 	'https://pl.rm-da-funk.ru/',
    API_C_DOMAIN: 	'https://pl.rm-da-funk.ru/',
    API_GATE: 		'https://pl.rm-da-funk.ru',
    COOKIE_TITLE: 	'pl_crm'
};

export default CONFIG;