import React, {useState, useEffect, useRef, useMemo} from 'react';
import classNames from 'classnames'
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Markers,
  Annotation,
  useMapContext
} from "react-simple-maps";

import {ManagerFilter} from "./ManagerFilter"

import allStates from "./../../APP/ASSETS/StatesAll.json";
import geoUrl from "./../../APP/ASSETS/StatesGeo.json";

import Fire from'./../../APP/ASSETS/fire.svg'
import Exclamation from'./../../APP/ASSETS/exclamation.svg'

import styles from "./MapStatistics.module.scss"

const StatusFilter = ({selectedStatus, setStatus}) =>
{
    const selectStatus = (status) =>
    {
        if (selectedStatus == status) {
            setStatus(false)
        } else {
            setStatus(status)
        }
    }

    return (
        <div className={styles.statusFilter}>
            <a
                className={classNames(styles.statusBlock, {[styles.selected]: selectedStatus == 'exclamation'})}
                onClick={() => selectStatus('exclamation')}
            >
                <img src={Exclamation} />
                <span>States with no sales past 3 month</span>
            </a>
            <a
                className={classNames(styles.statusBlock, {[styles.selected]: selectedStatus == 'fire'})}
                onClick={() => selectStatus('fire')}
            >
                <img src={Fire} />
                <span>Top 10 on sales last 3 month</span>
            </a>
        </div>
    )
}

const GeoSpot = ({geography, selectedManager, selectedStatus, data}) =>
{
    const centroid = geoCentroid(geography)
    const state = allStates.find(s => s.val === geography.id);

    const showFire = useMemo(() =>
    {
        if (selectedStatus == 'fire') {
            let useStates = data.slice(0,10);

            for (let i = 0; i < useStates.length; i++) {
                const currentState = useStates[i];
                if (currentState.val == state.val) {
                    if (currentState.salesCount > 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }, [data, state, selectedStatus])

    const showExclamation = useMemo(() =>
    {
        if (selectedStatus == 'exclamation') {
            for (let i = 0; i < data.length; i++) {
                const currentState = data[i];
                if (currentState.val == state.val) {
                    if (currentState.salesCount == 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }, [data, state, selectedStatus])

    const fillColor = useMemo(() =>
    {
        if (selectedManager) {
            for (let i = 0; i < data.length; i++) {
                const currentState = data[i];
                if (currentState.val == state.val) {
                    if (currentState.managersIds.includes(selectedManager.toString())) {
                        return '#42BBFF';
                    }
                }
            }
        }

        return "#C7C7C7";
    }, [data, state, selectedManager])

    return (
        <>
            <Geography
                key={geography.rsmKey}
                stroke="#FFF"
                geography={geography}
                fill={fillColor}
                style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                }}
            />
            {state && centroid[0] > -160 && centroid[0] < -67 && (
                <Marker coordinates={centroid} key={'marker_' + geography.rsmKey}>
                    <text className={styles.stateTitle} textAnchor="middle">
                        {state.id}
                    </text>
                    {showFire && <image xlinkHref={Fire}  textAnchor="middle" />}
                    {showExclamation && <image xlinkHref={Exclamation}  textAnchor="middle" />}
                </Marker>
            )}
        </>
    )
}

export const MapStatistics = ({managers, data}) =>
{
    const [selectedManager, selectManager] = useState(false)
    const [selectedStatus, selectStatus] = useState(false)

    return (
        <div className={styles.mapHolder}>
            <ManagerFilter
                managers={managers}
                selectedManager={selectedManager}
                setManager={selectManager}
            />
            <StatusFilter
                selectedStatus={selectedStatus}
                setStatus={selectStatus}
            />
            <ComposableMap projection="geoAlbersUsa">
                <Geographies geography={geoUrl}>
                    {({ geographies }) => {
                        return geographies.map((geography) => (
                            <GeoSpot
                                key={geography.rsmKey}
                                geography={geography}
                                selectedManager={selectedManager}
                                selectedStatus={selectedStatus}
                                data={data}
                            />
                        ))
                    }}
                </Geographies>
            </ComposableMap>
        </div>
    )
}

