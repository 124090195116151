import React, {useState, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import { OrdersListTableRow } from "./OrdersListTableRow"

import styles from "./OrdersListTable.module.scss"

export const Th = ({heading}) =>
{
    return (
        <th>
            <div className={styles.th}>
                {heading.title}
            </div>
        </th>
    )
}

export const OrdersListTable = ({orders, showButton, buttonClick}) =>
{
    const headings = [
        {title: 'ID', sortable: false},
        {title: 'Document', sortable: false},
        {title: 'Line', sortable: false},
        {title: 'Planned date', sortable: false},
        {title: 'Fact date', sortable: false},
        {title: 'Product', sortable: false},
        {title: 'Planned amount', sortable: false},
        {title: 'Fact amount', sortable: false},
        {title: 'Scrap', sortable: false},
        {title: 'Raw materials', sortable: false},
        {title: 'Status', sortable: false},
    ]

    return (
        <div className={styles.tableWrapper}>
            <table className={styles.salesTable}>
                <thead>
                    <tr>
                        {
                            headings.map((heading, index) => {
                                return <Th key={index} heading={heading} />
                            })
                        }
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => {
                        return <OrdersListTableRow key={index} order={order} />
                    })}
                </tbody>
            </table>
            {showButton && <a className={styles.loadMoreButton} onClick={() => buttonClick()}>Load more</a>}
        </div>
    )
}