import React, {Component} from 'react';
import {Route, Navigate, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"

import styles from "./Page.module.scss"

import { FilterBar } from "./../../APP/COMPONENTS/FilterBar"
import { OperationalColumns } from "../COMPONENTS/OperationalColumns"
import { MessageBox } from "../COMPONENTS/MessageBox"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER,
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class OperationalEfficiency extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            FILTER: 10
        }

        this.data_string = 'OperationalEfficiency';
    }

    componentDidMount = () =>
    {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.REFETCH !== prevProps.REFETCH) {
            if (this.props.REFETCH && this.props.REFETCH.length) {
                if (this.props.REFETCH.includes(this.data_string)) {
                    this.fetchData();
                }
            }
        }
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:     'getOperationalEfficiency',
                    token:      this.props.USER.token,
                    days:       this.state.FILTER
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, DATA: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    useFilter = (value) =>
    {
        if (value !== this.state.FILTER) {
            this.setState({FILTER: value}, () => this.fetchData());
        }
    }

    render() {
        if (!this.props.USER) {
            return <Navigate to="/"/>
        }

        return (
            <div className={styles.page}>
                <Helmet>
                    <title>Operational efficiency</title>
                </Helmet>
                <h1>Operational efficiency</h1>

                {!this.state.LOADED && <InnerLoader/>}

                {this.state.LOADED && !this.state.DATA.length && <MessageBox text="No data recieved from server!"/>}

                {this.state.LOADED && this.state.DATA.length && 
                    <>
                        <FilterBar
                            options={[
                                {key: 10, title: 'Last 10 days'},
                                {key: 30, title: 'Last 30 days'},
                                {key: 180, title: 'Last 6 months'},
                                {key: 360, title: 'Last year'}
                            ]}
                            selected={this.state.FILTER}
                            select={this.useFilter}
                        />
                        <OperationalColumns
                            data={this.state.DATA}
                        />
                    </>
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(OperationalEfficiency))
