import React, {Component} from 'react';
import classNames from 'classnames'
import styles from "./TextInput.module.scss"

export default class TextInput extends Component {

	constructor(props)
    {
        super(props);
    }

    componentDidupdate = (prevProps, prevState, snapshot) =>
    {

    }

    handleKeyDown = (e) =>
    {
        if (typeof this.props.handleKeyDown !== "undefined") {
            this.props.handleKeyDown(e);
        }
    }

	render() { 
        if (this.props.type == 'textarea') {
            return (
                <textarea 
                    name={this.props.name}
                    className={classNames(styles.input, styles.textarea, {[styles.inlineInput]: this.props.inlineInput})}
                    key={this.props.name}
                    placeholder={this.props.placeholder}
                    onKeyDown={(e) => this.handleKeyDown(e)} 
                    type={this.props.type} 
                    onChange={(e) => this.props.recordValue(e.target.value, this.props.name)}
                    autoComplete={this.props.autoComplete}
                >{this.props.value}</textarea>
            );
        }

        return (
            <input 
                name={this.props.name}
                className={classNames(styles.input, {[styles.inlineInput]: this.props.inlineInput})}
                key={this.props.name}
                placeholder={this.props.placeholder}
                onKeyDown={(e) => this.handleKeyDown(e)} 
                type={this.props.type} 
                defaultValue={this.props.value}
                onChange={(e) => this.props.recordValue(e.target.value, this.props.name)}
                autoComplete={this.props.autoComplete}
            />
    	);
	}
}