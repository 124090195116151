import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import classNames from 'classnames'

import styles from "./Modal.module.scss"

import Close from'./../../APP/ASSETS/close.svg' 

export const Modal = ({header, content, modalKey, thin = false, medium = false}) =>
{
    const [isVisible, setVisible] = useState(false)
    const dispatch = useDispatch()
    const MODALS_LIST = useSelector(state => state.MODALS_LIST)

    useEffect(() => { 
        if (MODALS_LIST[modalKey] !== "undefined") {
            if (MODALS_LIST[modalKey] && !isVisible) {
                setVisible(true)
            }

            if (!MODALS_LIST[modalKey] && isVisible) {
                setVisible(false)
            }
        }
     }, [MODALS_LIST, isVisible]);

    const closeModal = () =>
    {
        dispatch({
            type: 'TOGGLE_MODAL',
            data: {
                key: modalKey,
                status: false
            }
        })
        setVisible(false)
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalBackdrop} onClick={() => closeModal()} />
            <div className={classNames(styles.modalContent, {[styles.thin]: thin, [styles.medium]: medium})}>
                <img className={styles.modalClose} src={Close} onClick={() => closeModal()} />
                <div className={styles.modalBody}>
                    <div className={styles.modalHeader}>
                        {header}
                    </div>
                    {content}
                </div>
            </div>
        </div>
    )
}
