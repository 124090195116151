import Cookies from 'universal-cookie';
import CONFIG from './../SYSTEM/Config.js'

var initState = {
	USER: false,
	MODALS_LIST: {
		Settings: false,
		AddUser: false,
		EditUser: false,
		AddCustomer: false,
		EditCustomer: false,
		EditGroupPricing: false,
		EditGroupAmount: false,
		AddShortLink: false,
		EditShortLink: false,
		AddPromocode: false,
		EditPromocode: false,
		AddLanding: false,
		EditLanding: false,
		LandingSubmits: false,
		LandingVisits: false,
		ShortLinkVisits: false,
	},
	OPEN_LOGIN: false,
	REFETCH: [],
	HIDE_NAVS: false
};

const cookies = new Cookies();

var reducer = function(state = initState, action) {
	switch (action.type) {

		case 'TOGGLE_MODAL':{
			let MODALS_LIST = Object.assign({},state.MODALS_LIST);
			MODALS_LIST[action.data.key] = action.data.status;

			return {
				...state,
				MODALS_LIST: MODALS_LIST
			}
		}

		case 'DO_AUTH':
			cookies.set(CONFIG.COOKIE_TITLE, action.data[CONFIG.COOKIE_TITLE], { path: '/', time: action.data.time });
			return {
				...state,
				USER: action.data
			}

		case 'DO_LOG_OUT':
			cookies.set(CONFIG.COOKIE_TITLE, false, { path: '/', time: -1 });
			return {
				...state,
				USER: false
			}

		case 'REFETCH':
			return {
				...state,
				REFETCH: action.data
			}

		case 'HIDE_NAVS':
			return {
				...state,
				HIDE_NAVS: action.data
			}

		default:
			return state;
	}
}

export default reducer;