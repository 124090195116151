import React, {useState, useEffect, useMemo} from 'react';
import classNames from 'classnames'

import TriangleUp from'./../../../APP/ASSETS/triangle_up.svg'
import TriangleDown from'./../../../APP/ASSETS/triangle_down.svg'

import styles from "./GroupRawProducts.module.scss"

export const GroupRawProductFirstRow = ({sort, setSort}) =>
{
    return (
        <div className={classNames(styles.productRow, {[styles.noMarginBottom]: true})}>
            <div className={styles.productTitle} />
            <div className={styles.productAmount} />
            <div className={styles.statusLineHolder} />
            <div className={classNames(styles.toOrder, {[styles.noBackground]: true})}>
                To order:
                <span>
                    <a className={classNames({[styles.active]: sort == 'desc'})} onClick={() => setSort('desc')}>
                        <img src={TriangleDown} />
                    </a>
                    <a className={classNames({[styles.active]: sort == 'asc'})} onClick={() => setSort('asc')}>
                        <img src={TriangleUp} />
                    </a>
                </span>
            </div>
            <div className={classNames(styles.toOrder, {[styles.noBackground]: true})}>
                Sum on ware:
            </div>
        </div>
    )
}