import React, {Component} from 'react';
import {Route, Navigate, Link, useLoaderData} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { Request } from "./../../SYSTEM/Request"
import { withRouter } from "./../../hooks.js"

import styles from "./Landing.module.scss"

import { SalesChart } from "../COMPONENTS/Charts/SalesChart"
import { LandingForm } from "./Landing/LandingForm"
import { BigImage } from "./Landing/BigImage"
import { Loader } from "./Landing/Loader"
import InnerLoader from "../COMPONENTS/Common/InnerLoader"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        REFETCH: state.REFETCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Landing extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LOADED: false,
            LANDING_ID: this.props.location.pathname.split("/")[2],
            LANDING: false,
            FIELDS: {},
            submitted: false
        }

        this.data_string = 'Landing';
    }

    componentDidMount = () =>
    {
        this.props.hideNavs(true)
        this.fetchData();
    }

    fetchData = async () =>
    {
        if (!this.fetching) {
            this.fetching = true;

            try {
                let SEND_DATA = {
                    action:         'getLandingData',
                    landing_id:     this.state.LANDING_ID
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.fetching = false;
                    this.setState({LOADED: true, LANDING: data})
                } else {
                    this.fetching = false;
                    this.setState({LOADED: true, LANDING: []})
                }
            } catch (error) {
                console.log(error)
                this.fetching = false;
            }
        }
    }

    recordValue = (string, key) =>
    {
        let FIELDS = Object.assign({},{...this.state.FIELDS});
        FIELDS[key] = string;
        this.setState({FIELDS: FIELDS})
    }

    submit = async () =>
    {
        if (!this.submitting) {
            this.submitting = true;

            try {
                let SEND_DATA = {
                    action:         'submitLandingForm',
                    code:           this.state.LANDING.code,
                    fields:         this.state.FIELDS
                };

                let data = await Request(SEND_DATA,CONFIG.API_GATE);

                if (data !== false) {
                    this.setState({LOADED: true, submitted: true})
                }
                this.submitting = false;
            } catch (error) {
                console.log(error)
                this.submitting = false;
            }
        }
    }

    render() {
        if (!this.state.LOADED) {
            return (
                <div>
                    <Helmet>
                        <title>Загрузка ...</title>
                    </Helmet>
                    <Loader />
                </div>
            )
        }

        return (
            <div className={styles.landingPage} style={{background: this.state.LANDING.background}}>
                <Helmet>
                    <title>{this.state.LANDING.title}</title>
                </Helmet>
                <BigImage src={this.state.LANDING.image} />
                <div className={styles.middleBlock}>
                    <div className={styles.innerBlock}>
                        <div className={styles.textHolder}>
                            <div className={styles.title}>
                                {this.state.LANDING.title}
                            </div>
                            <div className={styles.description} dangerouslySetInnerHTML={{ __html: this.state.LANDING.description }} />
                        </div>
                        <div className={styles.formHolder}>
                            <LandingForm
                                fields={this.state.LANDING.fields}
                                recordValue={this.recordValue}
                                button={this.state.LANDING.button}
                                submit={this.submit}
                                submitted={this.state.submitted}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Landing))
