import React, {useState, useEffect, useMemo} from 'react';
import { sumBeautifier } from "./../../../SYSTEM/Functions"
import classNames from 'classnames';

import styles from "./OrdersListTable.module.scss"

export const Td = ({content}) =>
{
    return (
        <td>
            <div className={styles.td}>
                {content && (content.length || content !== '') ? content : '-'}
            </div>
        </td>
    )
}

export const OrdersListTableRow = ({order}) =>
{
    const materialsContent = useMemo(() =>
    {
        let products = [];
        for (let i = 0; i < order.materials.length; i++) {
            let product = order.materials[i];

            products.push(
                <div key={i} className={styles.productsRow}>
                    <span>{product.name}</span>
                    <br/>
                    {product.amount} {product.unit} / {sumBeautifier(product.sum)}
                </div>
            )
        }

        return (
            <>
                {products}
            </>
        )
    }, [order])

    const statusContent = useMemo(() =>
    {
        switch (order.status) {
            case 0:
                return 'NEW';
                break;
            case 1:
                return 'RELEASED';
                break;
            case 2:
                return 'CLOSED';
                break;
            case 3:
                return 'CANCELLED';
                break;
        }
    }, [order])

    return (
        <tr>
            <Td
                content={order.id}
            />
            <Td
                content={order.doc_number}
            />
            <Td
                content={order.line}
            />
            <Td
                content={order.planned_date}
            />
            <Td
                content={order.fact_date}
            />
            <Td
                content={order?.product.name}
            />
            <Td
                content={order.planned_amount + ' ' + order?.product.unit}
            />
            <Td
                content={order.fact_amount + ' ' + order?.product.unit}
            />
            <Td
                content={order.scrap}
            />
            <Td
                content={materialsContent}
            />
            <Td
                content={statusContent}
            />
        </tr>
    )
}