import React, {useState, useEffect} from 'react';
import classNames from 'classnames'

import styles from "./FilterBar.module.scss"

export const FilterBar = ({options, selected, select}) =>
{
    return (
        <div className={styles.filterBar}>
            {options.map((option, index) => {
                return (
                    <a
                        key={index}
                        className={classNames(styles.filterBtn, {[styles.selected]: option.key == selected})}
                        onClick={() => select(option.key)}
                    >
                        {option.color && option.color.length &&
                            <div className={styles.coloredCircle} style={{backgroundColor: option.color}} />
                        }
                        {option.title}
                    </a>
                )
            })}
        </div>
    )
}
