import React, {useState, useEffect, useMemo} from 'react';
import classNames from 'classnames'

import { CustomersTableRow } from "./CustomersTableRow"

import styles from "./CustomersTable.module.scss"

import TriangleUp from'./../../APP/ASSETS/triangle_up.svg'
import TriangleDown from'./../../APP/ASSETS/triangle_down.svg'

export const Th = ({heading, setSort, sort}) =>
{
    return (
        <th>
            <div className={styles.th}>
                {heading.title}
                {heading.sortable &&
                    <span>
                        <a className={classNames({[styles.active]: sort.type == heading.title && sort.direction == 'desc'})} onClick={() => setSort(heading.title, 'desc')}>
                            <img src={TriangleDown} />
                        </a>
                        <a className={classNames({[styles.active]: sort.type == heading.title && sort.direction == 'asc'})} onClick={() => setSort(heading.title, 'asc')}>
                            <img src={TriangleUp} />
                        </a>
                    </span>
                }
            </div>
        </th>
    )
}

export const CustomersTable = ({customers, filter, groups}) =>
{
    const headings =[
        {title: 'Менеджер', sortable: false},
        {title: 'Название', sortable: true},
        {title: 'Телефон', sortable: false},
        {title: 'E-mail', sortable: false},
        {title: 'Адрес', sortable: false},
    ]

    const [sort, setSorting] = useState({type: 'Name', direction: 'asc'})

    const setSort = (type, direction) =>
    {
        setSorting({
            type: type,
            direction: direction
        })
    }

    const filteredUsers = useMemo(() =>
    {
        let readyUsers = []
        for (let i = 0; i < customers.length; i++) {
            let customer = customers[i];
            
            let USE = true;

            if (filter.manager) {
                if (customer?.manager.id !== filter.manager) {
                    USE = false;
                }
            }

            if (USE && filter.group) {
                if (customer.groups.length > 0) {
                    if (filter.group == 'all') {
                        if (customer.groups.length !== groups.length) {
                            USE = false;
                        }
                    } else {
                        let selected = customer.groups.filter((group) => group.id == filter.group)
                        if (selected.length == 0) {
                            USE = false;
                        }
                    }
                } else {
                    USE = false;
                }
            }

            if (USE && filter.active) {
                if (filter.active == 1) {
                    if (customer.total_sales == 0) {
                        USE = false
                    }
                } else {
                    if (customer.total_sales > 0) {
                        USE = false
                    }
                }
            }

            if (USE) {
                readyUsers.push(customer)
            }
        }

        return readyUsers;

    }, [customers, filter])

    const sortedUsers = useMemo(() =>
    {
        switch (sort.type) {
            case 'Название':
                if (sort.direction == 'asc') {
                    return filteredUsers.sort(function(a, b) {
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    });
                } else {
                    return filteredUsers.sort(function(a, b) {
                        if(b.name < a.name) { return -1; }
                        if(b.name > a.name) { return 1; }
                        return 0;
                    });
                }
            break;

            case 'Average order':
                if (sort.direction == 'asc') {
                    return filteredUsers.sort(function(a, b) {
                        if(a.average_bill < b.average_bill) { return -1; }
                        if(a.average_bill > b.average_bill) { return 1; }
                        return 0;
                    });
                } else {
                    return filteredUsers.sort(function(a, b) {
                        if(b.average_bill < a.average_bill) { return -1; }
                        if(b.average_bill > a.average_bill) { return 1; }
                        return 0;
                    });
                }
            break;

            case 'Amount':
                if (sort.direction == 'asc') {
                    return filteredUsers.sort(function(a, b) {
                        if(a.total_sales < b.total_sales) { return -1; }
                        if(a.total_sales > b.total_sales) { return 1; }
                        return 0;
                    });
                } else {
                    return filteredUsers.sort(function(a, b) {
                        if(b.total_sales < a.total_sales) { return -1; }
                        if(b.total_sales > a.total_sales) { return 1; }
                        return 0;
                    });
                }
            break;

            case 'Past days':
                if (sort.direction == 'asc') {
                    return filteredUsers.sort(function(a, b) {
                        let aDays = false;
                        if (a.last_order) {
                            aDays = a.last_order.past_days
                        }

                        let bDays = false;
                        if (b.last_order) {
                            bDays = b.last_order.past_days
                        }
                
                        if(aDays < bDays) { return -1; }
                        if(aDays > bDays) { return 1; }
                        return 0;
                    });
                } else {
                    return filteredUsers.sort(function(a, b) {
                        let aDays = false;
                        if (a.last_order) {
                            aDays = a.last_order.past_days
                        }

                        let bDays = false;
                        if (b.last_order) {
                            bDays = b.last_order.past_days
                        }
                
                        if(bDays < aDays) { return -1; }
                        if(bDays > aDays) { return 1; }
                        return 0;
                    });
                }
            break;

        }

        return filteredUsers;
    }, [sort, filteredUsers])

    return (
        <div className={styles.tableWrapper}>
            <table className={styles.customersTable}>
                <thead>
                    <tr>
                        {
                            headings.map((heading, index) => {
                                return <Th key={index} heading={heading} setSort={setSort} sort={sort} />
                            })
                        }
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedUsers.map((customer, index) => {
                        return <CustomersTableRow key={index} customer={customer} />
                    })}
                </tbody>
            </table>
        </div>
    )
}
