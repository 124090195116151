import React, {Component} from 'react';
import classNames from 'classnames'

import styles from "./Loader.module.scss"

class InnerLoader extends Component {
    render()
    {
        return (
            <div className={ styles.loader }>
                <div className={ styles.clockLoader }/>
            </div>
        );
    }

}

export default InnerLoader;